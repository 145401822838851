import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { FaArrowLeft } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import * as Icons from 'react-icons/fa';
import SongEmbed from '../components/SongEmbed';

const PageContainer = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3;
  min-height: calc(100vh - 80px);
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: 'Rubik', sans-serif;
  color: #2E2E2E;
  text-decoration: none;
  margin-bottom: 20px;
  
  &:hover {
    text-decoration: underline;
  }
  
  svg {
    margin-right: 8px;
  }
`;

const PostHeader = styled.div`
  margin-bottom: 40px;
`;

const PostTitle = styled.h1`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  font-size: 32px;
  margin: 0 0 10px;
`;

const PostSubtitle = styled.h2`
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  color: #6A7D8B;
  font-size: 18px;
  margin: 0 0 20px;
`;

const PostMeta = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2E2E2E;
  color: white;
  border-radius: 8px;
  margin-right: 16px;
`;

const PostInfo = styled.div`
  font-family: 'Rubik', sans-serif;
`;

const Author = styled.div`
  font-weight: 600;
  color: #2E2E2E;
  margin-bottom: 4px;
`;

const PostDate = styled.div`
  font-size: 14px;
  color: #6A7D8B;
`;

const FeaturedImageContainer = styled.div`
  margin: 24px 0;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const FeaturedImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const MarkdownContent = styled.div`
  font-family: 'Rubik', sans-serif;
  color: #2E2E2E;
  line-height: 1.7;
  
  /* Styling for markdown elements */
  h1, h2, h3, h4, h5, h6 {
    font-family: 'DM Serif Text', serif;
    color: #2E2E2E;
    margin: 24px 0 16px;
  }
  
  h1 {
    font-size: 28px;
  }
  
  h2 {
    font-size: 24px;
  }
  
  h3 {
    font-size: 20px;
  }
  
  p {
    margin: 16px 0;
  }
  
  a {
    color: #6A7D8B;
    text-decoration: underline;
  }
  
  blockquote {
    position: relative;
    margin: 30px 0;
    padding: 24px 32px;
    background: linear-gradient(135deg, rgba(106, 125, 139, 0.1), rgba(46, 46, 46, 0.05));
    border-radius: 12px;
    font-style: normal;
    color: #2E2E2E;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    
    /* Decorative bar on the left */
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 6px;
      background: linear-gradient(to bottom, #6A7D8B, #2E2E2E);
      border-radius: 2px;
    }
    
    /* Decorative quotation mark */
    &::after {
      content: '"';
      position: absolute;
      right: 20px;
      bottom: -20px;
      font-family: 'DM Serif Text', serif;
      font-size: 120px;
      font-style: normal;
      color: rgba(46, 46, 46, 0.1);
      line-height: 1;
    }
    
    /* Add top and bottom decorative lines */
    p {
      position: relative;
      z-index: 1;
      font-family: 'DM Serif Text', serif;
      font-size: 24px;
      color: #2E2E2E;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: 0;
    }
    
    /* Apply a subtle hover effect */
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
    }
  }
  
  ul, ol {
    padding-left: 20px;
    margin: 16px 0;
  }
  
  li {
    margin: 8px 0;
  }
  
  code {
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
  }
  
  pre {
    background-color: #f0f0f0;
    padding: 16px;
    border-radius: 8px;
    overflow-x: auto;
    
    code {
      background-color: transparent;
      padding: 0;
    }
  }
  
  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Remove the first heading since we display it separately */
  h1:first-child {
    display: none;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #2E2E2E;
  font-family: 'Rubik', sans-serif;
  margin-top: 40px;
`;

// Add a custom renderer component that recognizes song embed patterns
const MarkdownWithSongEmbeds = ({ content }) => {
  // Regular expressions to match song embed patterns:
  // [song-card:123] for a SongCard with ID 123
  // [song-list-item:456] for a SongListItem with ID 456
  const songCardRegex = /\[song-card:([a-zA-Z0-9_-]+)\]/g;
  const songListItemRegex = /\[song-list-item:([a-zA-Z0-9_-]+)\]/g;
  
  // Process the markdown content to directly replace embeds instead of using placeholders
  const processContent = () => {
    // First collect all matches so we can identify song IDs
    const songCardMatches = [...content.matchAll(songCardRegex)];
    const songListItemMatches = [...content.matchAll(songListItemRegex)];
    
    // Track the matches for use in rendering
    return {
      content,
      songCardMatches,
      songListItemMatches
    };
  };

  const { content: markdownContent } = processContent();

  // Custom renderer that directly handles the song embed patterns
  const components = {
    // This is a special component that lets us parse and process text nodes
    text: ({ value }) => {
      // Process the text content for song embeds
      if (!value) return value;
      
      // Check for song card syntax
      const cardMatch = value.match(/\[song-card:([a-zA-Z0-9_-]+)\]/);
      if (cardMatch) {
        const songId = cardMatch[1];
        return <SongEmbed songId={songId} type="card" />;
      }
      
      // Check for song list item syntax
      const listMatch = value.match(/\[song-list-item:([a-zA-Z0-9_-]+)\]/);
      if (listMatch) {
        const songId = listMatch[1];
        return <SongEmbed songId={songId} type="list" />;
      }
      
      return value;
    },
    // Handle paragraphs that might contain the song embed syntax
    p: ({ children, node }) => {
      // Check if paragraph text contains song embed syntax
      const rawText = node.children
        .filter(child => child.type === 'text')
        .map(child => child.value)
        .join('');
      
      // If the paragraph contains ONLY a song embed, render the embed directly
      if (rawText.match(/^\s*\[song-(card|list-item):([a-zA-Z0-9_-]+)\]\s*$/)) {
        // Extract the type and ID from the syntax
        const match = rawText.match(/\[song-(card|list-item):([a-zA-Z0-9_-]+)\]/);
        if (match) {
          const type = match[1] === 'card' ? 'card' : 'list';
          const songId = match[2];
          return <SongEmbed songId={songId} type={type} />;
        }
      }
      
      // If the paragraph contains multiple song card embeds, render them in a flex container
      const multipleCardMatches = [...rawText.matchAll(/\[song-card:([a-zA-Z0-9_-]+)\]/g)];
      if (multipleCardMatches.length > 1) {
        const SongCardRow = styled.div`
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 20px;
          margin: 24px 0;
          width: 100%;
        `;
        
        return (
          <SongCardRow>
            {multipleCardMatches.map((match, index) => (
              <SongEmbed 
                key={`card-${match[1]}-${index}`} 
                songId={match[1]} 
                type="card"
                inRow={true}
              />
            ))}
          </SongCardRow>
        );
      }
      
      // If the paragraph contains multiple song list item embeds, render them in a column
      const multipleListMatches = [...rawText.matchAll(/\[song-list-item:([a-zA-Z0-9_-]+)\]/g)];
      if (multipleListMatches.length > 1) {
        const SongListColumn = styled.div`
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin: 24px 0;
          width: 100%;
        `;
        
        return (
          <SongListColumn>
            {multipleListMatches.map((match, index) => (
              <SongEmbed 
                key={`list-${match[1]}-${index}`} 
                songId={match[1]} 
                type="list"
                inRow={true}
              />
            ))}
          </SongListColumn>
        );
      }
      
      // Otherwise, render as normal paragraph
      return <p>{children}</p>;
    }
  };

  return (
    <ReactMarkdown 
      remarkPlugins={[remarkGfm]} 
      components={components}
    >
      {markdownContent}
    </ReactMarkdown>
  );
};

function BlogPostPage() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const postResponse = await fetch(`/blog-posts/${postId}.md`);
        
        if (!postResponse.ok) {
          throw new Error('Blog post not found');
        }
        
        const text = await postResponse.text();
        
        // Extract front matter with a more flexible regex for line endings
        const frontMatterRegex = /---\s*([\s\S]*?)\s*---/;
        const frontMatterMatch = text.match(frontMatterRegex);
        
        if (frontMatterMatch && frontMatterMatch[1]) {
          const frontMatter = frontMatterMatch[1];
          const metadata = {};
          
          // Parse front matter
          frontMatter.split(/\r?\n/).forEach(line => {
            const [key, ...valueParts] = line.split(':');
            if (key && valueParts.length) {
              metadata[key.trim()] = valueParts.join(':').trim();
            }
          });
          
          // Process featured image path if it exists
          if (metadata.featuredImage) {
            metadata.featuredImage = `/images/blog-post-features/${metadata.featuredImage}`;
          }
          
          setPost({
            id: postId,
            ...metadata,
            content: text.replace(frontMatterRegex, '')
          });
        } else {
          throw new Error('Invalid blog post format');
        }
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchBlogPost();
  }, [postId]);

  // Function to get the correct icon component
  const getIconComponent = (iconName) => {
    const Icon = Icons[iconName] || Icons.FaNewspaper;
    return <Icon size={20} />;
  };

  // Parse date string to ensure consistent handling
  const parseDate = (dateString) => {
    // Try to handle different date formats consistently
    let dateObj;
    
    // Check if we have a date in MM/DD/YYYY format
    const usFormatMatch = dateString.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
    if (usFormatMatch) {
      const [, month, day, year] = usFormatMatch;
      // Use ISO format for consistent parsing (YYYY-MM-DD)
      dateObj = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00.000Z`);
    } else {
      // If we have YYYY-MM-DD format or other format, use standard parsing
      // but force UTC interpretation by appending Z or using Date.UTC
      if (dateString.includes('T')) {
        // If it has a time component already
        dateObj = new Date(dateString);
      } else {
        // If it's just a date without time
        dateObj = new Date(`${dateString}T00:00:00.000Z`);
      }
    }
    
    // Verify we got a valid date
    if (isNaN(dateObj.getTime())) {
      console.error('Failed to parse date:', dateString);
      return null;
    }
    
    return dateObj;
  };

  // Format date for display
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    try {
      const dateObj = parseDate(dateString);
      if (!dateObj) return 'Invalid date';
      
      return dateObj.toLocaleDateString(undefined, options);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <ContentWrapper>
          <div>Loading blog post...</div>
        </ContentWrapper>
      </PageContainer>
    );
  }

  if (error || !post) {
    return (
      <PageContainer>
        <ContentWrapper>
          <BackLink to="/blog">
            <FaArrowLeft />
            Back to Blog
          </BackLink>
          <ErrorMessage>
            <h2>Error: {error || 'Blog post not found'}</h2>
            <p>The blog post you're looking for could not be found.</p>
          </ErrorMessage>
        </ContentWrapper>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Helmet>
        <title>{post.title} | Tempo Stock Blog</title>
        <meta name="description" content={post.subtitle} />
      </Helmet>
      <ContentWrapper>
        <BackLink to="/blog">
          <FaArrowLeft />
          Back to Blog
        </BackLink>
        
        <PostHeader>
          <PostTitle>{post.title}</PostTitle>
          <PostSubtitle>{post.subtitle}</PostSubtitle>
          
          <PostMeta>
            <IconWrapper>
              {getIconComponent(post.icon)}
            </IconWrapper>
            <PostInfo>
              <Author>By {post.author}</Author>
              <PostDate>{formatDate(post.date)}</PostDate>
            </PostInfo>
          </PostMeta>
        </PostHeader>
        
        {post.featuredImage && (
          <FeaturedImageContainer>
            <FeaturedImage src={post.featuredImage} alt={post.title} />
          </FeaturedImageContainer>
        )}
        
        <MarkdownContent>
          <MarkdownWithSongEmbeds content={post.content} />
        </MarkdownContent>
      </ContentWrapper>
    </PageContainer>
  );
}

export default BlogPostPage; 