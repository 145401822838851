import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FaNewspaper } from 'react-icons/fa';
import TopText from '../components/TopText';

const PageContainer = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3;
  min-height: calc(100vh - 80px);
`;

const ContentWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
`;

const BlogPostsGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
`;

const BlogPostCard = styled(Link)`
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    max-width: 100%;
  }
  
  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

const CardFooter = styled.div`
  position: relative;
  margin-top: auto;
  padding-top: 16px;
  width: 100%;
  height: 24px; /* Fixed height for the footer */
  
  @media (max-width: 480px) {
    height: 40px; /* Taller on mobile to accommodate stacked elements */
  }
`;

const TeamName = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  color: #6A7D8B;
  position: absolute;
  left: 0;
  top: 16px;
`;

const PostDate = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  color: #6A7D8B;
  position: absolute;
  right: 0;
  top: 16px;
  
  @media (max-width: 480px) {
    top: 36px;
    left: 0;
    right: auto;
  }
`;

const PostTitle = styled.h2`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  font-size: 28px;
  margin: 0 0 8px;
  word-break: break-word;
  
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const PostSubtitle = styled.h3`
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  color: #6A7D8B;
  font-size: 16px;
  margin: 0 0 16px;
  word-break: break-word;
  
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

// const Author = styled.div`
//   font-family: 'Rubik', sans-serif;
//   font-size: 14px;
//   color: #2E2E2E;
//   margin-bottom: 8px;
// `;

const FeaturedImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  opacity: 0.15;
  mask-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0));
  -webkit-mask-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0));
  
  @media (max-width: 768px) {
    width: 20%;
  }
  
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    opacity: 0.08;
    mask-image: none;
    -webkit-mask-image: none;
  }
`;

function BlogPage() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the blog posts
    const fetchBlogPosts = async () => {
      console.log("Starting to fetch blog posts");
      try {
        const response = await fetch('/blog-posts/');
        console.log("Directory fetch response:", response);
        
        // If we can't fetch directory listing, we'll handle this manually
        // by listing the known blog post files
        if (!response.ok) {
          console.log("Falling back to hardcoded blog post list");
          // Hard-coded list of blog posts for now
          const postFiles = [
            'first-blog-post.md',
            'second-blog-post.md',
            'third-blog-post.md'
          ];
          
          console.log("Attempting to fetch:", postFiles);
          
          const posts = await Promise.all(
            postFiles.map(async (filename) => {
              try {
                console.log(`Fetching ${filename}...`);
                const postResponse = await fetch(`/blog-posts/${filename}`);
                
                if (!postResponse.ok) {
                  console.error(`Failed to fetch ${filename}: ${postResponse.status} ${postResponse.statusText}`);
                  return null;
                }
                
                const text = await postResponse.text();
                console.log(`Received content for ${filename}, length: ${text.length}`);
                
                // Extract front matter with a more flexible regex for line endings
                const frontMatterRegex = /---\s*([\s\S]*?)\s*---/;
                const frontMatterMatch = text.match(frontMatterRegex);
                
                if (frontMatterMatch && frontMatterMatch[1]) {
                  const frontMatter = frontMatterMatch[1];
                  const metadata = {};
                  
                  // Parse front matter
                  frontMatter.split(/\r?\n/).forEach(line => {
                    const [key, ...valueParts] = line.split(':');
                    if (key && valueParts.length) {
                      metadata[key.trim()] = valueParts.join(':').trim();
                    }
                  });
                  
                  console.log(`Parsed metadata for ${filename}:`, metadata);
                  
                  // If featured image is not specified, set a default image or null
                  if (!metadata.featuredImage) {
                    metadata.featuredImage = null;
                  } else {
                    // Prepend the path to the image directory
                    metadata.featuredImage = `/images/blog-post-features/${metadata.featuredImage}`;
                  }
                  
                  // Set default visibility to true if not specified
                  if (metadata.visible === undefined) {
                    metadata.visible = true;
                  } else {
                    // Convert string 'true'/'false' to boolean
                    metadata.visible = metadata.visible.toLowerCase() === 'true';
                  }
                  
                  return {
                    id: filename.replace('.md', ''),
                    ...metadata,
                    content: text.replace(frontMatterRegex, '')
                  };
                } else {
                  console.error(`No front matter found in ${filename}`);
                  return null;
                }
              } catch (err) {
                console.error(`Error processing ${filename}:`, err);
                return null;
              }
            })
          );
          
          // Filter out any null posts and sort by date (newest first)
          const validPosts = posts.filter(post => post !== null && post.visible);
          console.log("Valid posts found:", validPosts.length);
          
          validPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
          
          setBlogPosts(validPosts);
        } else {
          // If we can get a directory listing, we'd process it here
          // But for simplicity, we're using the hardcoded approach
          console.log("Directory listing available, but using hardcoded approach");
          
          const postFiles = [
            'coming-soon.md',
            'welcome-to-tempo-stock.md',
            'mason.md'
          ];
          
          const posts = await Promise.all(
            postFiles.map(async (filename) => {
              try {
                console.log(`Fetching ${filename}...`);
                const postResponse = await fetch(`/blog-posts/${filename}`);
                
                if (!postResponse.ok) {
                  console.error(`Failed to fetch ${filename}: ${postResponse.status} ${postResponse.statusText}`);
                  return null;
                }
                
                const text = await postResponse.text();
                console.log(`Received content for ${filename}, length: ${text.length}`);
                
                // Extract front matter with a more flexible regex for line endings
                const frontMatterRegex = /---\s*([\s\S]*?)\s*---/;
                const frontMatterMatch = text.match(frontMatterRegex);
                
                if (frontMatterMatch && frontMatterMatch[1]) {
                  const frontMatter = frontMatterMatch[1];
                  const metadata = {};
                  
                  // Parse front matter
                  frontMatter.split(/\r?\n/).forEach(line => {
                    const [key, ...valueParts] = line.split(':');
                    if (key && valueParts.length) {
                      metadata[key.trim()] = valueParts.join(':').trim();
                    }
                  });
                  
                  console.log(`Parsed metadata for ${filename}:`, metadata);
                  
                  // If featured image is not specified, set a default image or null
                  if (!metadata.featuredImage) {
                    metadata.featuredImage = null;
                  } else {
                    // Prepend the path to the image directory
                    metadata.featuredImage = `/images/blog-post-features/${metadata.featuredImage}`;
                  }
                  
                  // Set default visibility to true if not specified
                  if (metadata.visible === undefined) {
                    metadata.visible = true;
                  } else {
                    // Convert string 'true'/'false' to boolean
                    metadata.visible = metadata.visible.toLowerCase() === 'true';
                  }
                  
                  return {
                    id: filename.replace('.md', ''),
                    ...metadata,
                    content: text.replace(frontMatterRegex, '')
                  };
                } else {
                  console.error(`No front matter found in ${filename}`);
                  return null;
                }
              } catch (err) {
                console.error(`Error processing ${filename}:`, err);
                return null;
              }
            })
          );
          
          // Filter out any null posts and sort by date (newest first)
          const validPosts = posts.filter(post => post !== null && post.visible);
          console.log("Valid posts found:", validPosts.length);
          
          validPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
          
          setBlogPosts(validPosts);
        }
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchBlogPosts();
  }, []);

  // Parse date string to ensure consistent handling
  const parseDate = (dateString) => {
    // Try to handle different date formats consistently
    let dateObj;
    
    // Check if we have a date in MM/DD/YYYY format
    const usFormatMatch = dateString.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
    if (usFormatMatch) {
      const [, month, day, year] = usFormatMatch;
      // Use ISO format for consistent parsing (YYYY-MM-DD)
      dateObj = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00.000Z`);
    } else {
      // If we have YYYY-MM-DD format or other format, use standard parsing
      // but force UTC interpretation by appending Z or using Date.UTC
      if (dateString.includes('T')) {
        // If it has a time component already
        dateObj = new Date(dateString);
      } else {
        // If it's just a date without time
        dateObj = new Date(`${dateString}T00:00:00.000Z`);
      }
    }
    
    // Verify we got a valid date
    if (isNaN(dateObj.getTime())) {
      console.error('Failed to parse date:', dateString);
      return null;
    }
    
    return dateObj;
  };

  // Format date for display
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    try {
      const dateObj = parseDate(dateString);
      if (!dateObj) return 'Invalid date';
      
      return dateObj.toLocaleDateString(undefined, options);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  return (
    <PageContainer>
      <Helmet>
        <title>Blog | Tempo Stock</title>
        <meta name="description" content="Latest news, updates, and insights from Tempo Stock - the stock music library built and owned by composers." />
      </Helmet>
      <ContentWrapper>
        <TopText icon={FaNewspaper} title="Blog" />
        
        {loading ? (
          <div>Loading blog posts...</div>
        ) : error ? (
          <div>Error loading blog posts: {error}</div>
        ) : blogPosts.length === 0 ? (
          <div>
            <p>No blog posts found. Please check the console for debugging information.</p>
            <p>Looking for blog posts in: /blog-posts/</p>
          </div>
        ) : (
          <BlogPostsGrid>
            {blogPosts.map((post) => (
              <BlogPostCard key={post.id} to={`/blog/${post.id}`}>
                {post.featuredImage && (
                  <FeaturedImage src={post.featuredImage} />
                )}
                <ContentContainer>
                  <CardHeader>
                    <div>
                      <PostTitle>{post.title}</PostTitle>
                      <PostSubtitle>{post.subtitle}</PostSubtitle>
                    </div>
                  </CardHeader>
                  <CardFooter>
                    <TeamName>{post.author}</TeamName>
                    <PostDate>{formatDate(post.date)}</PostDate>
                  </CardFooter>
                </ContentContainer>
              </BlogPostCard>
            ))}
          </BlogPostsGrid>
        )}
      </ContentWrapper>
    </PageContainer>
  );
}

export default BlogPage; 