// src/pages/PricingPage.js
import React, { useContext } from 'react';
import styled from 'styled-components';
import TopText from '../components/TopText';
import { FaTag, FaSeedling, FaGem, FaCrown } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

// Toggle this flag to enable/disable pricing page
const PRICING_ENABLED = true;

const PageContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3;
  
  @media (min-width: 768px) {
    padding: 20px;
  }
`;

const PricingGrid = styled.div`
  display: flex;
  gap: 15px;
  margin: 20px 0;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  
  @media (min-width: 768px) {
    gap: 30px;
    margin: 40px 0;
    padding: 0;
  }
`;

const PopularBadge = styled.div`
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #2E2E2E, #444);
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  z-index: 2;
  white-space: nowrap;
  
  @media (min-width: 768px) {
    padding: 5px 15px;
    font-size: 14px;
  }
`;

const PricingCard = styled.div`
  background: white;
  border-radius: 15px;
  padding: 20px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  position: relative;
  
  @media (min-width: 768px) {
    padding: 30px;
    border-radius: 20px;
  }
  
  ${props => props.$featured && `
    background: white;
    position: relative;
    transform: scale(1.02);
    
    @media (min-width: 768px) {
      transform: scale(1.05);
    }
    
    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      background: linear-gradient(
        to bottom right,
        #2E2E2E,
        #444,
        #2E2E2E
      );
      border-radius: 17px;
      z-index: -1;
      
      @media (min-width: 768px) {
        border-radius: 22px;
      }
    }
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        125deg,
        #ffffff 0%,
        #f8f8f8 25%,
        #ffffff 35%,
        #f8f8f8 45%,
        #ffffff 50%,
        #f8f8f8 55%,
        #ffffff 65%,
        #f8f8f8 75%,
        #ffffff 100%
      );
      background-size: 200% 200%;
      background-position: 0% 0%;
      border-radius: 15px;
      z-index: -1;
      transition: all 1.5s ease;
      
      @media (min-width: 768px) {
        border-radius: 20px;
      }
    }
    
    box-shadow: 
      0 8px 25px rgba(0, 0, 0, 0.15),
      0 0 20px rgba(46, 46, 46, 0.1);
  `}

  &:hover {
    transform: ${props => props.$featured ? 'scale(1.04)' : 'scale(1.02)'};
    
    @media (min-width: 768px) {
      transform: ${props => props.$featured ? 'scale(1.08)' : 'scale(1.03)'};
    }
    
    ${props => props.$featured && `
      &::after {
        background-position: 100% 100%;
      }
      box-shadow: 
        0 8px 30px rgba(0, 0, 0, 0.2),
        0 0 30px rgba(46, 46, 46, 0.15);
    `}
  }
`;

const TierName = styled.h2`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  margin-bottom: 8px;
  font-size: 20px;
  
  @media (min-width: 768px) {
    margin-bottom: 10px;
    font-size: 24px;
  }
`;

const Price = styled.div`
  font-family: 'Rubik', sans-serif;
  margin: 15px 0;
  
  @media (min-width: 768px) {
    margin: 20px 0;
  }
  
  .amount {
    font-size: 30px;
    font-weight: bold;
    color: #2E2E2E;
    
    @media (min-width: 768px) {
      font-size: 36px;
    }
  }
  
  .period {
    color: #6A6A6A;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 15px 0;
  flex-grow: 1;
  width: 100%;

  li {
    font-family: 'Rubik', sans-serif;
    color: #6A6A6A;
    margin: 12px 0;
    text-align: center;
    font-size: 14px;
    
    @media (min-width: 768px) {
      margin: 15px 0;
      font-size: 16px;
    }
  }
`;

const SubscribeButton = styled.button`
  padding: 10px 20px;
  background-color: ${props => props.$featured ? '#2E2E2E' : 'white'};
  color: ${props => props.$featured ? 'white' : '#2E2E2E'};
  border: 2px solid #2E2E2E;
  border-radius: 8px;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: 15px;

  @media (min-width: 768px) {
    padding: 12px 30px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
  }

  &:hover {
    background-color: ${props => props.$featured ? '#444' : '#2E2E2E'};
    color: white;
  }
`;

const DisabledButton = styled(SubscribeButton)`
  background-color: #cccccc;
  border-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  
  &:hover {
    background-color: #cccccc;
    color: #666666;
  }
`;

// Add these constants at the top level
const STRIPE_PRICES = {
  Pro: 'price_1QwybVAMCn7sSNAisMgMhdnz',
  Premier: 'price_1Qwye4AMCn7sSNAi5MD4TViz'
};

// Add payment URL constant
const PAYMENTS_URL = process.env.REACT_APP_PAYMENTS_URL || 'https://tempostock.com/api/payments';

const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
  padding: 20px;
`;

const ComingSoonText = styled.h2`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  font-size: 36px;
  margin-bottom: 16px;
`;

const ComingSoonSubtext = styled.p`
  font-family: 'Rubik', sans-serif;
  color: #6A6A6A;
  font-size: 18px;
  max-width: 600px;
  line-height: 1.6;
`;

const TierIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  
  svg {
    font-size: 28px;
    color: ${props => props.$featured ? '#2E2E2E' : props.$color || '#2E2E2E'};
    transition: transform 0.3s ease;
  }

  ${props => props.$featured && `
    svg {
      filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
    }
  `}

  @media (min-width: 768px) {
    svg {
      font-size: 32px;
    }
  }
`;

function PricingPage() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const handleSignUp = () => {
    navigate('/login?tab=signup');
  };

  const handleSubscription = async (plan) => {
    if (!user) {
      handleSignUp();
      return;
    }

    // Debug user object
    console.log('User object:', {
      id: user.id,
      email: user.email,
      account_type: user.account_type,
      fullObject: JSON.stringify(user, null, 2)
    });
    
    if (!user.id) {
      console.error('User ID is missing from user object. Please try logging out and logging back in.');
      return;
    }

    try {
      const checkoutUrl = `${PAYMENTS_URL}/create-checkout-session`;
      console.log('Making request to:', checkoutUrl);
      const payload = {
        priceId: STRIPE_PRICES[plan],
        userId: user.id,
      };
      console.log('Request payload:', payload);
      
      const response = await fetch(checkoutUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response not OK:', {
          status: response.status,
          statusText: response.statusText,
          body: errorText
        });
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Checkout session response:', data);
      
      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      // TODO: Add proper error handling/notification here
    }
  };

  // Helper function to determine button text based on current plan and target plan
  const getButtonText = (targetPlan) => {
    if (!user) return 'Sign Up';
    
    if (user.account_type === targetPlan) return 'Current Plan';
    
    if (user.account_type === 'Free') return 'Upgrade';
    
    const planRanking = { Free: 1, Pro: 2, Premier: 3 };
    return planRanking[targetPlan] < planRanking[user.account_type] ? 'Downgrade' : 'Upgrade';
  };

  const handleButtonClick = (plan) => {
    if (plan === 'Free') {
      // Handle downgrade to free plan
      if (!user) {
        handleSignUp();
        return;
      }

      if (user.account_type === 'Free') {
        return; // Already on free plan
      }

      // Show confirmation dialog
      if (!window.confirm('Are you sure you want to cancel your subscription? This will take effect at the end of your current billing period.')) {
        return;
      }

      // Cancel subscription
      fetch(`${PAYMENTS_URL}/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Your subscription has been cancelled and will end at the end of your current billing period.');
          window.location.reload(); // Refresh to update UI
        } else {
          throw new Error(data.error || 'Failed to cancel subscription');
        }
      })
      .catch(error => {
        console.error('Error canceling subscription:', error);
        alert('Failed to cancel subscription. Please try again or contact support.');
      });
      return;
    }
    
    if (!user) {
      handleSignUp();
      return;
    }

    if (user.account_type === plan) {
      return; // Already on this plan
    }

    handleSubscription(plan);
  };

  return (
    <PageContainer>
      <TopText icon={FaTag} title="Pricing Plans" />
      {PRICING_ENABLED ? (
        <PricingGrid>
          <PricingCard>
            <TierIcon $color="#4caf50">
              <FaSeedling />
            </TierIcon>
            <TierName>Free</TierName>
            <Price>
              <span className="amount">$0</span>
              <span className="period">/month</span>
            </Price>
            <FeatureList>
              <li>• 1 download per week</li>
              <li>• Must credit Tempo Stock</li>
              <li>• General commercial terms</li>
            </FeatureList>
            {user ? (
              user.account_type === 'Free' ? (
                <DisabledButton disabled>
                  Current Plan
                </DisabledButton>
              ) : (
                <SubscribeButton onClick={() => handleButtonClick('Free')}>
                  {getButtonText('Free')}
                </SubscribeButton>
              )
            ) : (
              <SubscribeButton onClick={handleSignUp}>
                Sign Up
              </SubscribeButton>
            )}
          </PricingCard>

          <PricingCard $featured>
            <PopularBadge>Most Popular</PopularBadge>
            <TierIcon $featured>
              <FaGem />
            </TierIcon>
            <TierName>Pro</TierName>
            <Price>
              <span className="amount">$4.99</span>
              <span className="period">/month</span>
            </Price>
            <FeatureList>
              <li>• 10 downloads per week</li>
              <li>• No credit to Tempo Stock needed</li>
              <li>• General commercial terms</li>
            </FeatureList>
            {user && user.account_type === 'Pro' ? (
              <DisabledButton disabled>
                Current Plan
              </DisabledButton>
            ) : (
              <SubscribeButton $featured onClick={() => handleButtonClick('Pro')}>
                {getButtonText('Pro')}
              </SubscribeButton>
            )}
          </PricingCard>

          <PricingCard>
            <TierIcon>
              <FaCrown />
            </TierIcon>
            <TierName>Premier</TierName>
            <Price>
              <span className="amount">$14.99</span>
              <span className="period">/month</span>
            </Price>
            <FeatureList>
              <li>• Unlimited downloads</li>
              <li>• No credit to Tempo Stock needed</li>
              <li>• General commercial terms</li>
            </FeatureList>
            {user && user.account_type === 'Premier' ? (
              <DisabledButton disabled>
                Current Plan
              </DisabledButton>
            ) : (
              <SubscribeButton onClick={() => handleButtonClick('Premier')}>
                {getButtonText('Premier')}
              </SubscribeButton>
            )}
          </PricingCard>
        </PricingGrid>
      ) : (
        <ComingSoonContainer>
          <ComingSoonText>Coming Soon</ComingSoonText>
          <ComingSoonSubtext>
            We're working on exciting new pricing plans to better serve our community. 
            Check back soon for updates!
          </ComingSoonSubtext>
        </ComingSoonContainer>
      )}
    </PageContainer>
  );
}

export default PricingPage;