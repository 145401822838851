import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getSignedUrl } from '../utils/getSignedUrl';
import WaveformVisualizer from './WaveformVisualizer';
import { tagIcons, tagColors } from '../constants/tagConfig';
import { useSongContext } from '../SongContext';

const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: box-shadow 0.2s;
  height: 110px;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    gap: 15px;
    padding: 15px;
    height: auto;
  }

  @media (max-width: 480px) {
    gap: 10px;
    padding: 10px;
    flex-wrap: wrap;
    height: auto;
  }
`;

const AlbumArt = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 4px;
  background-image: ${props => props.$image ? `url(${props.$image})` : 'none'};
  background-size: cover;
  background-position: center;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

const SongInfo = styled.div`
  flex: 0 0 auto;
  min-width: 0;
  max-width: 300px;

  @media (max-width: 768px) {
    max-width: 200px;
  }

  @media (max-width: 480px) {
    flex: 1;
    max-width: calc(100% - 100px); /* Account for album art + button + gap */
  }
`;

const SongTitle = styled.h3`
  margin: 0 0 8px 0;
  font-family: 'Rubik', sans-serif;
  font-size: 1.1em;
  color: #2e2e2e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 480px) {
    font-size: 1em;
    margin-bottom: 4px;
  }
`;

const TagContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    gap: 4px;
    margin-bottom: 4px;
  }
`;

const TagPill = styled(Link)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid ${props => props.color || '#000'};
  border-radius: 16px;
  color: ${props => props.color || '#000'};
  font-family: 'Rubik', sans-serif;
  font-size: 0.8em;
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.color || '#000'};
    color: #fff;
  }

  svg {
    margin-right: 4px;
    font-size: 0.9em;
  }

  @media (max-width: 480px) {
    padding: 2px 6px;
    font-size: 0.7em;
    border-radius: 12px;

    svg {
      font-size: 0.8em;
      margin-right: 2px;
    }
  }
`;

const MetadataContainer = styled.div`
  display: flex;
  gap: 12px;
  font-family: 'Rubik', sans-serif;
  font-size: 0.9em;
  color: #666;

  @media (max-width: 480px) {
    gap: 8px;
    font-size: 0.8em;
  }
`;

const MetadataItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const WaveformContainer = styled.div`
  flex: 1;
  min-width: 300px;
  overflow: hidden;
  width: 0;
  border-radius: 0 3px 3px 0;

  @media (max-width: 768px) {
    min-width: 200px;
  }

  @media (max-width: 480px) {
    order: 3;
    min-width: 0;
    width: 100%;
    margin-top: 10px;
    flex-basis: 100%;
  }
`;

const DownloadButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 50%;
  margin-left: 15px;
  color: #444;
  transition: background 0.2s;
  
  &:hover {
    background: #e0e0e0;
  }

  @media (max-width: 768px) {
    width: 36px;
    height: 36px;
    margin-left: 10px;
  }

  @media (max-width: 480px) {
    width: 32px;
    height: 32px;
    margin-left: auto;
  }
`;

// eslint-disable-next-line react-hooks/exhaustive-deps
const SongListItem = ({ song, isPlaying: propsIsPlaying, onPlayToggle, progress: propsProgress = 0 }) => {
  const [albumArtUrl, setAlbumArtUrl] = useState('');
  const [audioBuffer, setAudioBuffer] = useState(null);
  const audioLoaded = useRef(false);
  const [isVisible, setIsVisible] = useState(false);
  const itemRef = useRef(null);
  
  // Use the shared context
  const { 
    currentSongId, 
    isPlaying: contextIsPlaying, 
    currentProgress,
    loadAudioBuffer,
    playSong,
    pausePlayback,
    seekTo,
    audioRef,
    audioBuffers
  } = useSongContext();
  
  // Determine if this specific song is playing
  const isThisSongPlaying = currentSongId === song.song_id && contextIsPlaying;
  
  // Set up intersection observer to detect visibility
  useEffect(() => {
    if (!itemRef.current) return;
    
    // Store ref value to avoid the React Hook warning
    const currentItemRef = itemRef.current;
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { 
        rootMargin: '200px 0px', // Start loading when within 200px of viewport
        threshold: 0.1 
      }
    );
    
    observer.observe(currentItemRef);
    
    return () => {
      // Use the stored reference value in the cleanup function
      observer.unobserve(currentItemRef);
    };
  }, []);
  
  // Load album art
  useEffect(() => {
    const loadArt = async () => {
      try {
        if (song.song_art) {
          const artUrl = await getSignedUrl(song.song_art);
          if (artUrl) setAlbumArtUrl(artUrl);
        }
      } catch (error) {
        console.error('Error loading album art:', error);
      }
    };

    loadArt();
  }, [song.song_art]);

  // Load audio buffer only when visible or when playing
  useEffect(() => {
    const loadBuffer = async () => {
      // Skip if already loaded or no file to load
      if (!song.song_file_wm || audioLoaded.current) return;
      
      // Only load if visible or currently playing
      if (!isVisible && !isThisSongPlaying) return;
      
      try {
        // Load from context to ensure shared buffer
        const result = await loadAudioBuffer(song.song_id, song.song_file_wm);
        if (result?.buffer) {
          audioLoaded.current = true;
          setAudioBuffer(result.buffer);
        }
      } catch (error) {
        console.error('Error loading audio buffer:', error);
      }
    };

    loadBuffer();
  }, [song.song_id, song.song_file_wm, loadAudioBuffer, isVisible, isThisSongPlaying]);

  // Check if we already have buffer in context
  useEffect(() => {
    if (audioBuffers[song.song_id]?.buffer && !audioBuffer) {
      audioLoaded.current = true;
      setAudioBuffer(audioBuffers[song.song_id].buffer);
    }
  }, [song.song_id, audioBuffers, audioBuffer]);

  const handlePlayToggle = (percentage) => {
    // If a percentage is provided, it's a seek action
    if (typeof percentage === 'number') {
      // If already playing this song, just seek
      if (isThisSongPlaying) {
        seekTo(percentage);
      } else {
        // Otherwise start playing from position
        playSong(song.song_id, song.song_file_wm, percentage);
      }
    } else {
      // Toggle play/pause
      if (isThisSongPlaying) {
        pausePlayback();
      } else {
        playSong(song.song_id, song.song_file_wm);
      }
    }
    
    // Notify parent component if needed
    if (onPlayToggle) {
      onPlayToggle(isThisSongPlaying ? false : true);
    }
  };

  return (
    <ListItem ref={itemRef}>
      <AlbumArt $image={albumArtUrl} />
      <SongInfo>
        <SongTitle>{song.song_title}</SongTitle>
        <TagContainer>
          {song.song_tags.slice(0, window.innerWidth < 480 ? 2 : 3).map((tag) => {
            const IconComponent = tagIcons[tag] || tagIcons.Piano;
            return (
              <TagPill
                key={tag}
                to={`/${tag.toLowerCase()}`}
                color={tagColors[tag] || '#000'}
              >
                <IconComponent />
                {tag}
              </TagPill>
            );
          })}
        </TagContainer>
        <MetadataContainer>
          <MetadataItem>{song.song_bpm}</MetadataItem>
          <MetadataItem>{song.song_key}</MetadataItem>
        </MetadataContainer>
      </SongInfo>
      <WaveformContainer>
        <WaveformVisualizer
          audioBuffer={audioBuffer}
          audioElement={audioRef.current}
          isPlaying={isThisSongPlaying}
          onPlayToggle={handlePlayToggle}
          progress={currentSongId === song.song_id ? currentProgress : 0}
        />
      </WaveformContainer>
      <DownloadButton to={`/songs/${song.song_id}`} title="Go to song page">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="7 10 12 15 17 10"></polyline>
          <line x1="12" y1="15" x2="12" y2="3"></line>
        </svg>
      </DownloadButton>
    </ListItem>
  );
};

export default SongListItem; 