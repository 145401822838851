// src/pages/UserPage.js
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { MdAccountCircle, MdInsertChart, MdFileDownload, MdDownload } from 'react-icons/md'; 
import { FaPlay, FaCalendarAlt, FaChartLine, FaHistory, FaCrown, FaGem, FaSeedling } from 'react-icons/fa';
import TopText from '../components/TopText';
import { Helmet } from 'react-helmet-async';
import { getSignedUrl } from '../utils/getSignedUrl';

// Styled component for the User Information Container
const UserContainer = styled.div`
  max-width: 800px;
  margin: 0 auto; /* Center the container */
  padding: 40px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  font-family: 'Rubik', sans-serif;
  position: relative; /* To position the FreeBadge absolutely if needed */

  @media (max-width: 600px) {
    padding: 20px;
    margin: 30px 15px;
    width: auto;
  }
`;

// Styled component for the Email and Badge Container
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  @media (max-width: 480px) {
    padding: 15px;
  }
`;

// Styled component for individual detail sections
const DetailSection = styled.div`
  width: 100%;

  h3 {
    font-size: 16px;
    margin-bottom: 8px;
    color: #555;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: 'Rubik', sans-serif;
  }

  .badge {
    margin-left: auto;
  }

  @media (max-width: 480px) {
    p {
      flex-wrap: wrap;
      gap: 8px;
    }
    
    .badge {
      margin-left: 0;
      margin-top: 8px;
    }
  }
`;

// Styled component for the Reset Password Form
const ResetForm = styled.form`
  margin-top: 30px;
  font-family: 'Rubik', sans-serif;
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  h3 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #2e2e2e;
    font-weight: 600;
    position: relative;
    padding-bottom: 10px;
    
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 3px;
      background-color: #2e2e2e;
      border-radius: 3px;
    }
  }

  label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-weight: 500;
  }

  input {
    width: 100%;
    padding: 12px 15px;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    transition: all 0.3s ease;
    background-color: white;
    box-sizing: border-box;

    &:focus {
      outline: none;
      border-color: #2e2e2e;
      box-shadow: 0 0 0 3px rgba(46, 46, 46, 0.15);
    }
  }

  button {
    background-color: #2e2e2e;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
    font-family: 'Rubik', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
      background-color: #232323;
      transform: translateY(-2px);
    }

    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
      transform: none;
    }
  }

  p {
    margin-top: 15px;
    font-size: 14px;
    color: #28a745;
    padding: 10px;
    background-color: rgba(40, 167, 69, 0.1);
    border-radius: 6px;
    text-align: center;
  }

  p.error {
    color: #dc3545;
    background-color: rgba(220, 53, 69, 0.1);
  }
`;

// Styled component for the Logout Button
const LogoutButton = styled.button`
  margin-top: 30px;
  background-color: white;
  color: #dc3545;
  border: 2px solid #dc3545;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  transition: all 0.3s ease;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background-color: #dc3545;
    color: white;
  }
`;

// Styled component for the section tabs
const SectionTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  gap: 10px;
  
  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

// Fixed the 'active' prop warning by using $active (transient prop)
const TabButton = styled.button`
  background-color: ${props => props.$active ? '#2e2e2e' : '#f5f5f5'};
  color: ${props => props.$active ? 'white' : '#333'};
  border: ${props => props.$active ? 'none' : '1px solid #ddd'};
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.$active ? '#232323' : '#e0e0e0'};
  }
`;

// New styled component for the account info cards layout
const AccountInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

// Stats Card components
const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const StatCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    font-size: 28px;
    color: #2e2e2e;
    margin-bottom: 10px;
    height: 28px; /* Fixed height to ensure consistency */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
  }
  
  .value {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin: 5px 0;
  }
  
  .label {
    font-size: 14px;
    color: #666;
  }
`;

// Download history components
const DownloadList = styled.div`
  margin-top: 20px;
`;

const DownloadItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #f9f9f9;
  }
  
  &:last-child {
    border-bottom: none;
  }
`;

// Fixed the SongArt component to properly display images
const SongArt = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: #eaeaea; /* Fallback color */
  border: 1px solid #ddd;
  margin-right: 15px;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  background-image: ${props => props.imageUrl ? `url(${props.imageUrl})` : 'url(https://via.placeholder.com/60?text=Music)'};
`;

const DownloadInfo = styled.div`
  flex-grow: 1;
  
  h4 {
    font-size: 16px;
    color: #333;
    margin: 0 0 5px 0;
  }
  
  p {
    font-size: 14px;
    color: #666;
    margin: 0;
  }
`;

const DownloadDate = styled.div`
  font-size: 14px;
  color: #888;
  display: flex;
  align-items: center;
  gap: 5px;
  
  @media (max-width: 480px) {
    margin-top: 5px;
  }
`;

// Pagination controls
const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

// Fixed the 'active' prop warning by using $active (transient prop)
const PageButton = styled.button`
  background-color: ${props => props.$active ? '#2e2e2e' : '#f5f5f5'};
  color: ${props => props.$active ? 'white' : '#333'};
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  font-family: 'Rubik', sans-serif;
  
  &:hover {
    background-color: ${props => props.disabled ? (props.$active ? '#2e2e2e' : '#f5f5f5') : (props.$active ? '#232323' : '#e0e0e0')};
  }
`;

const UserPage = () => {
  const { user, logout, getUserStats, getUserDownloads } = useContext(AuthContext);
  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState('account');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  // State for user stats
  const [stats, setStats] = useState(null);
  const [statsLoading, setStatsLoading] = useState(false);
  
  // State for download history
  const [downloads, setDownloads] = useState([]);
  const [downloadsPagination, setDownloadsPagination] = useState({
    total: 0,
    page: 1,
    limit: 10,
    pages: 0
  });
  const [downloadsLoading, setDownloadsLoading] = useState(false);
  const [signedImageUrls, setSignedImageUrls] = useState({});

  // Fetch user stats function
  const fetchUserStats = async () => {
    setStatsLoading(true);
    try {
      const userStats = await getUserStats();
      if (userStats) {
        setStats(userStats);
      }
    } catch (error) {
      console.error('Error fetching user stats:', error);
    } finally {
      setStatsLoading(false);
    }
  };

  // Fetch download history function
  const fetchUserDownloads = async (page = 1) => {
    setDownloadsLoading(true);
    try {
      const result = await getUserDownloads(page);
      const downloadsList = result.downloads || [];
      setDownloads(downloadsList);
      setDownloadsPagination(result.pagination || {
        total: 0,
        page: 1,
        limit: 10,
        pages: 0
      });
      
      // Process album art URLs for the downloads
      const imageUrlPromises = downloadsList.map(download => {
        if (!download.song_art) return null;
        
        const artPath = download.song_art.startsWith('/') 
          ? download.song_art.slice(1) 
          : download.song_art;
        
        return getSignedUrl(artPath)
          .then(signedUrl => {
            if (signedUrl) {
              return { id: download.id || download.song_id, url: signedUrl };
            }
            return null;
          })
          .catch(error => {
            console.error(`Error getting signed URL for ${download.song_title}:`, error);
            return null;
          });
      });
      
      Promise.all(imageUrlPromises)
        .then(results => {
          const urlMap = {};
          results.forEach(result => {
            if (result) {
              urlMap[result.id] = result.url;
            }
          });
          setSignedImageUrls(urlMap);
        });
        
    } catch (error) {
      console.error('Error fetching user downloads:', error);
    } finally {
      setDownloadsLoading(false);
    }
  };

  // Fetch user stats when the Stats tab is selected
  useEffect(() => {
    if ((activeSection === 'stats' || activeSection === 'account') && user && !stats) {
      fetchUserStats();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection, user, stats]);

  // Fetch download history when the Downloads tab is selected
  useEffect(() => {
    if (activeSection === 'downloads' && user) {
      fetchUserDownloads(downloadsPagination.page);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection, user, downloadsPagination.page]);

  if (!user) {
    return (
      <p style={{ textAlign: 'center', fontFamily: 'Rubik, sans-serif', color: '#333' }}>
        Loading...
      </p>
    );
  }

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setLoading(true);

    const token = localStorage.getItem('token');
    if (!token) {
      setError('Unauthorized');
      setLoading(false);
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Optional: If you secure this endpoint
        },
        body: JSON.stringify({ email: user.email, newPassword }),
      });

      if (res.ok) {
        setMessage('Password updated successfully.');
        setNewPassword('');
      } else {
        const errorData = await res.json();
        setError(errorData.error || 'Failed to update password.');
      }
    } catch (err) {
      setError('An error occurred while updating your password.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  // Format date for downloads
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  };

  // Render the account section
  const renderAccountSection = () => {
    // Debugging to check if user data is available
    console.log('User data:', user);
    console.log('Created at:', user.created_at);

    return (
    <>
      <DetailsContainer>
        {/* Email Section with Badge */}
        <DetailSection>
          <h3>Email Address</h3>
          <p>
            {user.email}
          </p>
        </DetailSection>
      </DetailsContainer>

      {/* Account Info Cards */}
      <AccountInfoContainer>
        <StatCard>
          {user.account_type === 'Premier' ? (
            <FaCrown className="icon" size={28} style={{ color: '#FFD700' }} />
          ) : user.account_type === 'Pro' ? (
            <FaGem className="icon" size={28} />
          ) : (
            <FaSeedling className="icon" size={28} />
          )}
          <div className="value">{user.account_type}</div>
          <div className="label">Account Type</div>
        </StatCard>
        
        {/* Member Since Card - Ensuring it always renders */}
        <StatCard>
          <FaCalendarAlt className="icon" size={28} />
          <div className="value">
            {user.created_at 
              ? (() => {
                  try {
                    return new Date(user.created_at).toLocaleDateString('en-US', { 
                      year: '2-digit', 
                      month: '2-digit', 
                      day: '2-digit' 
                    });
                  } catch (e) {
                    console.error('Error formatting date:', e);
                    return 'N/A';
                  }
                })()
              : 'N/A'}
          </div>
          <div className="label">Member Since</div>
        </StatCard>
        
        {stats && (
          <StatCard>
            <FaChartLine className="icon" size={28} />
            <div className="value">
              {stats.unlimited_downloads ? '∞' : stats.weekly_download_limit}
            </div>
            <div className="label">Weekly Download Limit</div>
          </StatCard>
        )}
      </AccountInfoContainer>

      {/* Reset Password Form */}
      <ResetForm onSubmit={handlePasswordReset}>
        <h3>Change Password</h3>
        <label htmlFor="new-password">New Password</label>
        <input
          type="password"
          id="new-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          placeholder="Enter new password"
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Updating...' : 'Update Password'}
        </button>
        {message && <p>{message}</p>}
        {error && <p className="error">{error}</p>}
      </ResetForm>

      {/* Logout Button */}
      <LogoutButton onClick={handleLogout}>
        <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4M16 17l5-5-5-5M21 12H9"/>
        </svg>
        Logout
      </LogoutButton>
    </>
  );
};

  // Render the stats section
  const renderStatsSection = () => {
    if (statsLoading) {
      return <p style={{ textAlign: 'center', padding: '30px' }}>Loading stats...</p>;
    }

    if (!stats) {
      return <p style={{ textAlign: 'center', padding: '30px' }}>No stats available</p>;
    }

    return (
      <>
        <StatsGrid>
          <StatCard>
            <FaPlay className="icon" size={28} />
            <div className="value">{stats.total_plays || 0}</div>
            <div className="label">Total Plays</div>
          </StatCard>
          
          <StatCard>
            <MdDownload className="icon" size={28} />
            <div className="value">{stats.total_downloads || 0}</div>
            <div className="label">Total Downloads</div>
          </StatCard>
          
          <StatCard>
            <FaHistory className="icon" size={28} />
            <div className="value">{stats.recent_downloads || 0}</div>
            <div className="label">Recent Downloads (7 days)</div>
          </StatCard>
        </StatsGrid>
      </>
    );
  };

  // Render the downloads section
  const renderDownloadsSection = () => {
    if (downloadsLoading) {
      return <p style={{ textAlign: 'center', padding: '30px' }}>Loading downloads...</p>;
    }

    if (!downloads || downloads.length === 0) {
      return (
        <p style={{ textAlign: 'center', padding: '30px' }}>You haven't downloaded any tracks yet.</p>
      );
    }

    return (
      <>
        <DownloadList>
          {downloads.map((download, index) => {
            const downloadId = download.id || download.song_id;
            const imageUrl = signedImageUrls[downloadId];
            
            return (
              <DownloadItem key={`download-${downloadId || index}`}>
                <SongArt imageUrl={imageUrl} />
                <DownloadInfo>
                  <h4>{download.song_title}</h4>
                  <p>
                    <a 
                      href={`/song/${download.song_id || download.id}`} 
                      style={{ 
                        color: '#2e2e2e', 
                        textDecoration: 'none',
                        fontWeight: '500',
                        transition: 'color 0.2s ease'
                      }}
                      onMouseOver={(e) => e.target.style.color = '#0056b3'}
                      onMouseOut={(e) => e.target.style.color = '#2e2e2e'}
                    >
                      View song page
                    </a>
                  </p>
                </DownloadInfo>
                <DownloadDate>
                  <FaCalendarAlt /> {formatDate(download.downloaded_at)}
                </DownloadDate>
              </DownloadItem>
            );
          })}
        </DownloadList>
        
        {/* Pagination controls */}
        {downloadsPagination.pages > 1 && (
          <PaginationControls>
            <PageButton 
              onClick={() => fetchUserDownloads(1)} 
              disabled={downloadsPagination.page === 1}
            >
              First
            </PageButton>
            
            <PageButton 
              onClick={() => fetchUserDownloads(downloadsPagination.page - 1)} 
              disabled={downloadsPagination.page === 1}
            >
              Previous
            </PageButton>
            
            <span style={{ padding: '8px 0' }}>
              Page {downloadsPagination.page} of {downloadsPagination.pages}
            </span>
            
            <PageButton 
              onClick={() => fetchUserDownloads(downloadsPagination.page + 1)} 
              disabled={downloadsPagination.page === downloadsPagination.pages}
            >
              Next
            </PageButton>
            
            <PageButton 
              onClick={() => fetchUserDownloads(downloadsPagination.pages)} 
              disabled={downloadsPagination.page === downloadsPagination.pages}
            >
              Last
            </PageButton>
          </PaginationControls>
        )}
      </>
    );
  };

  // Render the appropriate section based on the active tab
  const renderActiveSection = () => {
    switch (activeSection) {
      case 'stats':
        return renderStatsSection();
      case 'downloads':
        return renderDownloadsSection();
      case 'account':
      default:
        return renderAccountSection();
    }
  };

  return (
    <>
      <Helmet>
        <title>My Account | Tempo Stock</title>
        <meta name="description" content="Manage your Tempo Stock account settings and preferences." />
        <meta property="og:title" content="My Account | Tempo Stock" />
        <meta property="og:description" content="Manage your Tempo Stock account settings and preferences." />
        <meta name="twitter:title" content="My Account | Tempo Stock" />
        <meta name="twitter:description" content="Manage your Tempo Stock account settings and preferences." />
      </Helmet>
      
      {/* Header Section with Icon and Title */}
      <TopText icon={MdAccountCircle} title="My Account" />

      {/* User Information Container */}
      <UserContainer>
        {/* Section Tabs */}
        <SectionTabs>
          <TabButton 
            $active={activeSection === 'account'} 
            onClick={() => setActiveSection('account')}
          >
            <MdAccountCircle /> Account
          </TabButton>
          <TabButton 
            $active={activeSection === 'stats'} 
            onClick={() => setActiveSection('stats')}
          >
            <MdInsertChart /> Stats
          </TabButton>
          <TabButton 
            $active={activeSection === 'downloads'} 
            onClick={() => setActiveSection('downloads')}
          >
            <MdFileDownload /> Downloads
          </TabButton>
        </SectionTabs>

        {/* Content for the selected section */}
        {renderActiveSection()}
      </UserContainer>
    </>
  );
};

export default UserPage;