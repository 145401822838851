// src/components/Header.js
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #E3E3E3;
  margin: 0;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2E2E2E; /* Deep Charcoal */

  img {
    display: block;
    height: 40px; /* Adjust as needed */
  }

  /* Text element for the logo */
  .logo-text {
    margin-left: 10px;
    font-family: 'DM Serif Text', serif;
    font-size: 28px;
    color: #2E2E2E;
    font-weight: 700;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 20px 0 0;

  /* Base styles for all links */
  & a {
    margin-left: 20px;
    text-decoration: none;
    color: #2E2E2E;
    font-size: 16px;
    line-height: 1;
    padding: 0;
    font-weight: 600;
  }

  /* Desktop-only styles for account link */
  & a.account-link {
    background-color: #2E2E2E;
    color: white;
    padding: 8px 16px;
    border-radius: 8px;
    transition: background-color 0.2s;

    &:hover {
      background-color: #444;
    }
  }

  /* Mobile styles */
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    background-color: #E3E3E3;
    padding: 20px;
    box-shadow: 0 5px 8px -2px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transform: translateY(${props => props.isOpen ? '0' : '-100%'});
    opacity: ${props => props.isOpen ? '1' : '0'};
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
    pointer-events: ${props => props.isOpen ? 'all' : 'none'};
    margin-top: -1px;

    /* Reset margins for mobile */
    & a {
      margin: 8px 0;
      margin-left: 0;
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 20px; /* Add consistent padding for all links */
      box-sizing: border-box;
    }

    /* Mobile account link styles */
    & a.account-link {
      margin: 8px 0;
      padding: 0 20px; /* Same padding as other links */
      background-color: #2E2E2E;
      color: white;
      border-radius: 8px;
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HamburgerIcon = styled.div`
  width: 24px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #2E2E2E;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 8px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 16px;
      transform-origin: left center;
    }
  }

  &.open span {
    &:nth-child(1) {
      transform: rotate(45deg);
      top: -1px;
      left: 4px;
    }

    &:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
      top: 17px;
      left: 4px;
    }
  }
`;

const HamburgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  padding: 10px;
  padding-right: 0;
  margin-right: 0;
  position: relative;
  z-index: 1001;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  user-select: none;
  touch-action: manipulation;

  &:focus {
    outline: none;
  }

  &:active {
    background: none;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

function Header() {
  const { user } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HeaderContainer>
      <LogoLink to="/">
        <img src="/images/logo/logo_iconmark.png" alt="Tempo Stock Logo" />
        <span className="logo-text">tempo stock</span>
      </LogoLink>
      <HamburgerButton onClick={toggleMenu} aria-label="Toggle menu">
        <HamburgerIcon className={isMenuOpen ? 'open' : ''}>
          <span></span>
          <span></span>
          <span></span>
        </HamburgerIcon>
      </HamburgerButton>
      <NavLinks isOpen={isMenuOpen}>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/trending">Trending</Link>
        <Link to="/blog">Blog</Link>
        <Link to="/pricing">Pricing</Link>
        {user ? (
          <Link to="/user" className="account-link">My Account</Link>
        ) : (
          <Link to="/login" className="account-link">Login</Link>
        )}
      </NavLinks>
    </HeaderContainer>
  );
}

export default Header;