import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { FaPlay, FaPause } from 'react-icons/fa';
import { getAudioContext } from '../utils/audioContext';
import { throttle } from 'lodash';

const WaveformContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 60px;
  padding: 0 12px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  flex: 1;
  min-width: 0;
  margin-left: 8px;
  position: relative;

  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 480px) {
    height: 44px;
    padding: 0 8px;
    gap: 6px;
    margin-left: 0;
    border-radius: 4px;
    width: 100%;
  }
`;

const PlayButton = styled.button`
  background: none;
  border: none;
  color: #2e2e2e;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: color 0.2s;
  flex-shrink: 0;
  margin-right: 8px;
  width: 40px;

  &:hover {
    color: #000;
  }

  svg {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    padding: 6px;
    margin-right: 4px;
    width: 32px;

    svg {
      font-size: 14px;
    }
  }
`;

const WaveformCanvas = styled.canvas`
  flex: 1;
  height: 100%;
  cursor: pointer;
  min-width: 0;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

const LoadingBar = styled.div`
  width: 70%;
  height: 4px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;
    background: #2e2e2e;
    border-radius: 2px;
    animation: loading 1.5s infinite ease-in-out;
  }

  @keyframes loading {
    0% {
      left: -30%;
    }
    100% {
      left: 100%;
    }
  }
`;

/**
 * Visualizer component for audio waveforms
 * @param {Object} props
 * @param {string} [props.audioUrl] - URL to audio file (optional if audioBuffer provided)
 * @param {AudioBuffer} [props.audioBuffer] - Pre-loaded audio buffer (preferred)
 * @param {HTMLAudioElement} [props.audioElement] - Audio element reference from parent
 * @param {boolean} props.isPlaying - Whether audio is currently playing
 * @param {Function} props.onPlayToggle - Callback for play/pause/seek
 * @param {number} props.progress - Playback progress (0-100)
 */
const WaveformVisualizer = ({ 
  audioUrl, 
  audioBuffer: providedAudioBuffer, 
  audioElement, 
  isPlaying, 
  onPlayToggle, 
  progress = 0 
}) => {
  const canvasRef = useRef(null);
  const progressRef = useRef(progress);
  const [isLoading, setIsLoading] = useState(!providedAudioBuffer);
  const [error, setError] = useState(null);
  const [localAudioBuffer, setLocalAudioBuffer] = useState(providedAudioBuffer);
  const [waveformData, setWaveformData] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  
  // Update progress ref when prop changes
  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);
  
  // Preprocess waveform data to reduce real-time calculations
  const preprocessWaveformData = useCallback((buffer, maxPoints = 100) => {
    if (!buffer) return null;
    
    // Get raw audio data
    const channelData = buffer.getChannelData(0);
    const dataPoints = [];
    
    // Calculate how many samples to skip
    const sampleStep = Math.max(1, Math.floor(channelData.length / maxPoints));
    
    for (let i = 0; i < maxPoints; i++) {
      let min = 1.0;
      let max = -1.0;
      
      // Find min/max in this data segment
      for (let j = 0; j < sampleStep; j++) {
        const index = Math.min(channelData.length - 1, i * sampleStep + j);
        const datum = channelData[index];
        if (datum < min) min = datum;
        if (datum > max) max = datum;
      }
      
      dataPoints.push({ min, max });
    }
    
    return dataPoints;
  }, []);
  
  // Draw the waveform using preprocessed data
  const drawWaveform = useCallback((data, width, height, progress) => {
    const canvas = canvasRef.current;
    if (!canvas || !data) return;

    const ctx = canvas.getContext('2d');
    const amp = height / 2;

    // Clear canvas
    ctx.clearRect(0, 0, width, height);

    // Draw waveform baseline
    ctx.beginPath();
    ctx.moveTo(0, amp);
    ctx.lineTo(width, amp);
    ctx.strokeStyle = 'rgba(46, 46, 46, 0.2)';
    ctx.lineWidth = 1;
    ctx.stroke();

    // Calculate actual points to draw based on canvas width
    const pointsToRender = Math.min(data.length, width);
    const pointStep = Math.max(1, Math.floor(data.length / pointsToRender));
    
    // Draw vertical lines for waveform
    ctx.strokeStyle = '#2e2e2e';
    ctx.lineWidth = 1.75;
    
    // Scale factor to normalize the amplitude while preserving variation
    const scaleFactor = 0.85;
    
    for (let i = 0; i < pointsToRender; i++) {
      const dataIndex = Math.min(data.length - 1, Math.floor(i * pointStep));
      const point = data[dataIndex];
      
      // Apply non-linear scaling to preserve more variation
      // Math.sign preserves direction while Math.pow creates more variation
      const minScaled = Math.sign(point.min) * Math.pow(Math.abs(point.min), 0.8) * scaleFactor;
      const maxScaled = Math.sign(point.max) * Math.pow(Math.abs(point.max), 0.8) * scaleFactor;
      
      const minY = (1 + minScaled) * amp;
      const maxY = (1 + maxScaled) * amp;
      const x = i * (width / pointsToRender);
      
      // Start a new path for each vertical line
      ctx.beginPath();
      ctx.moveTo(x, minY);
      ctx.lineTo(x, maxY);
      ctx.stroke();
    }

    // Draw progress overlay with smooth animation
    const progressWidth = Math.max(0, Math.min(width, width * (progress / 100)));
    if (progressWidth > 0) {
      ctx.fillStyle = 'rgba(46, 46, 46, 0.3)';
      ctx.fillRect(0, 0, progressWidth, height);
    }
  }, []);

  // Throttled resize handler to avoid excessive processing
  const handleResize = useMemo(() => throttle(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const computedStyle = getComputedStyle(canvas);
      const width = parseInt(computedStyle.getPropertyValue('width'), 10);
      
      if (width !== canvasWidth) {
        canvas.width = width || 200;
        setCanvasWidth(width);
        
        // Only redraw if we have data
        if (waveformData) {
          drawWaveform(waveformData, canvas.width, canvas.height, progressRef.current);
        }
      }
    }
  }, 100), [canvasWidth, drawWaveform, waveformData]);
  
  // Throttled progress animator for smoother updates
  const animateProgress = useMemo(() => throttle(() => {
    if (waveformData && canvasRef.current) {
      drawWaveform(
        waveformData, 
        canvasRef.current.width, 
        canvasRef.current.height, 
        progressRef.current
      );
    }
  }, 30), [waveformData, drawWaveform]);

  // Intersection Observer to only render when visible
  useEffect(() => {
    if (!containerRef.current) return;
    
    // Store ref value to avoid the React Hook warning
    const currentContainerRef = containerRef.current;
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );
    
    observer.observe(currentContainerRef);
    
    return () => {
      // Use the stored reference value in the cleanup function
      observer.unobserve(currentContainerRef);
    };
  }, []);

  // Load audio buffer if not provided
  useEffect(() => {
    if (providedAudioBuffer) {
      setLocalAudioBuffer(providedAudioBuffer);
      setIsLoading(false);
      return;
    }
    
    // Skip if no URL or buffer already set
    if (!audioUrl || localAudioBuffer) return;
    
    // Only load if component is visible
    if (!isVisible) return;
    
    const loadBuffer = async () => {
      try {
        setIsLoading(true);
        
        // Use the shared audio context
        const audioContext = getAudioContext();
        
        // Fetch and decode
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const buffer = await audioContext.decodeAudioData(arrayBuffer);
        
        setLocalAudioBuffer(buffer);
        setIsLoading(false);
      } catch (err) {
        console.error('Error loading audio buffer:', err);
        setError('Failed to load audio visualization');
        setIsLoading(false);
      }
    };

    loadBuffer();
  }, [audioUrl, providedAudioBuffer, localAudioBuffer, isVisible]);

  // Process audio data once buffer is loaded
  useEffect(() => {
    if (localAudioBuffer && !waveformData) {
      // Use more or fewer points depending on device performance
      const maxPoints = window.innerWidth < 768 ? 80 : 150;
      
      // Process waveform data in the next idle period
      if (window.requestIdleCallback) {
        window.requestIdleCallback(() => {
          const data = preprocessWaveformData(localAudioBuffer, maxPoints);
          setWaveformData(data);
        });
      } else {
        // Fallback for browsers without requestIdleCallback
        setTimeout(() => {
          const data = preprocessWaveformData(localAudioBuffer, maxPoints);
          setWaveformData(data);
        }, 0);
      }
    }
  }, [localAudioBuffer, waveformData, preprocessWaveformData]);

  // Draw waveform after canvas dimensions are known
  useEffect(() => {
    if (!canvasRef.current || !waveformData) return;
    
    // Set initial dimensions
    const computedStyle = getComputedStyle(canvasRef.current);
    const width = parseInt(computedStyle.getPropertyValue('width'), 10);
    canvasRef.current.width = width || 200;
    setCanvasWidth(width);
    
    // Initial draw
    drawWaveform(
      waveformData, 
      canvasRef.current.width, 
      canvasRef.current.height, 
      progressRef.current
    );
  }, [waveformData, drawWaveform]);

  // Animate progress changes only when component is visible
  useEffect(() => {
    // Only animate if component is in viewport
    if (!isVisible) return;
    
    animateProgress();
    
    return () => {
      animateProgress.cancel();
    };
  }, [progress, isVisible, animateProgress]);

  // Redraw on resize
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
    };
  }, [handleResize]);

  // Handle click on the waveform for seeking
  const handleClick = (e) => {
    if (isLoading || error) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = (x / rect.width) * 100;

    // Call the parent's play toggle with percentage for seeking
    onPlayToggle(percentage);
  };

  return (
    <WaveformContainer ref={containerRef} onClick={handleClick}>
      <PlayButton 
        onClick={(e) => {
          e.stopPropagation();
          onPlayToggle();
        }}
        aria-label={isPlaying ? 'Pause' : 'Play'}
      >
        {isPlaying ? <FaPause /> : <FaPlay />}
      </PlayButton>
      <WaveformCanvas
        ref={canvasRef}
        height={window.innerWidth <= 480 ? 44 : 70}
      />
      {isLoading && (
        <LoadingContainer>
          <LoadingBar />
        </LoadingContainer>
      )}
    </WaveformContainer>
  );
};

export default WaveformVisualizer; 