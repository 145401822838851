import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SongCard from './SongCard';
import SongListItem from './SongListItem';
import { getSongById } from '../utils/songUtils';

// Create a simplified styled wrapper for SongCard
const CardWrapper = styled.div`
  width: 250px;
  
  /* Override song card styling for blog posts */
  & > a {
    aspect-ratio: 0.714; /* Maintain original aspect ratio */
    min-height: 230px !important; /* Smaller height */
    
    /* Make title white */
    h3 {
      color: white !important;
      font-weight: 600;
    }
  }

  /* Adjust the play button position for smaller card */
  & .play-button {
    bottom: 165px !important; /* Moved up from 115px to 135px */
  }
`;

// Create a styled wrapper for SongListItem to fix tag styling
const ListItemWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  
  /* Fix tag styles in list items */
  & a[href^="/"] {
    text-decoration: none !important;
    
    &:hover {
      text-decoration: none !important;
    }
  }
`;

const EmbedContainer = styled.div`
  margin: 24px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ErrorMessage = styled.div`
  padding: 16px;
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 8px;
  margin: 20px 0;
  max-width: 500px;
`;

const LoadingContainer = styled.div`
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 8px;
  text-align: center;
  color: #6c757d;
  max-width: 500px;
`;

/**
 * SongEmbed - Renders either a SongCard or SongListItem component based on a song ID
 * @param {Object} props - Component props
 * @param {string} props.songId - The ID of the song to embed
 * @param {string} props.type - The type of embed: "card" or "list" (default: "card")
 * @param {boolean} props.inRow - Whether this embed is part of a row (for styling purposes)
 */
const SongEmbed = ({ songId, type = "card", inRow = false }) => {
  const [song, setSong] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Add a reference to the card container for direct DOM manipulation
  const cardRef = React.useRef(null);

  // Apply styling adjustments after component mounts and whenever song changes
  useEffect(() => {
    if (song) {
      // Function to fix styling after render
      const adjustStyling = () => {
        if (cardRef.current && type === "card") {
          // Find the play button and adjust its position
          const playButton = cardRef.current.querySelector('.play-button');
          if (playButton) {
            playButton.style.bottom = '140px';
          }
          
          // Find the progress bar container and adjust its position
          const progressContainer = cardRef.current.querySelector('div[style*="flex-direction: column"]');
          if (progressContainer) {
            progressContainer.style.bottom = '75px';
          }
        }
      };

      // Use a small timeout to ensure DOM is fully rendered
      const timeout = setTimeout(adjustStyling, 100);
      return () => clearTimeout(timeout);
    }
  }, [song, type]);

  useEffect(() => {
    const fetchSong = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const songData = await getSongById(songId);
        if (songData) {
          setSong(songData);
        } else {
          setError(`Could not find song with ID: ${songId}`);
        }
      } catch (err) {
        console.error('Error fetching song for embed:', err);
        setError(`Error loading song: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (songId) {
      fetchSong();
    } else {
      setError('No song ID provided');
      setLoading(false);
    }
  }, [songId]);

  // If we're rendering inside a row, don't use the container
  const renderContent = () => {
    if (loading) {
      return <LoadingContainer>Loading song...</LoadingContainer>;
    }

    if (error) {
      return <ErrorMessage>{error}</ErrorMessage>;
    }

    if (!song) {
      return null;
    }

    return type === "card" ? (
      <CardWrapper ref={cardRef}>
        <SongCard 
          song={song} 
          shouldTrackPlay={true}
          hidePlayControls={false}
        />
      </CardWrapper>
    ) : (
      <ListItemWrapper>
        <SongListItem 
          song={song}
        />
      </ListItemWrapper>
    );
  };

  // If we're in a row of cards, don't add the extra container with centering
  if (inRow) {
    return renderContent();
  }

  return (
    <EmbedContainer>
      {renderContent()}
    </EmbedContainer>
  );
};

export default SongEmbed; 