import { getSignedUrl } from './getSignedUrl';

// Singleton AudioContext
let audioContext = null;

// Audio decoding queue and processing state
const decodingQueue = [];
const MAX_CONCURRENT_DECODING = 3;
let activeDecodingCount = 0;
let isProcessingQueue = false;

// LRU Cache implementation
class LRUCache {
  constructor(maxSize = 20) {
    this.maxSize = maxSize;
    this.cache = new Map();
    this.usage = new Map(); // Track usage counts
  }

  get(key) {
    if (!this.cache.has(key)) return null;
    
    // Update usage counter
    this.usage.set(key, Date.now());
    return this.cache.get(key);
  }

  set(key, value) {
    // If cache is at capacity, remove least recently used item
    if (this.cache.size >= this.maxSize && !this.cache.has(key)) {
      let oldestKey = null;
      let oldestTime = Infinity;
      
      for (const [k, time] of this.usage.entries()) {
        if (time < oldestTime) {
          oldestTime = time;
          oldestKey = k;
        }
      }
      
      if (oldestKey) {
        this.cache.delete(oldestKey);
        this.usage.delete(oldestKey);
      }
    }
    
    // Add/update item
    this.cache.set(key, value);
    this.usage.set(key, Date.now());
  }

  has(key) {
    return this.cache.has(key);
  }

  delete(key) {
    this.cache.delete(key);
    this.usage.delete(key);
  }

  clear() {
    this.cache.clear();
    this.usage.clear();
  }
  
  getSize() {
    return this.cache.size;
  }
}

// Initialize LRU cache with size limit
const audioBufferCache = new LRUCache(20);

// Get or create AudioContext
export const getAudioContext = () => {
  if (!audioContext) {
    audioContext = new (window.AudioContext || window.webkitAudioContext)();
  }
  return audioContext;
};

/**
 * Process the decoding queue with concurrency limit
 */
const processDecodingQueue = async () => {
  if (isProcessingQueue || decodingQueue.length === 0 || activeDecodingCount >= MAX_CONCURRENT_DECODING) {
    return;
  }
  
  isProcessingQueue = true;
  
  while (decodingQueue.length > 0 && activeDecodingCount < MAX_CONCURRENT_DECODING) {
    const { url, arrayBuffer, resolve, reject } = decodingQueue.shift();
    activeDecodingCount++;
    
    try {
      const context = getAudioContext();
      const audioBuffer = await context.decodeAudioData(arrayBuffer);
      
      // Cache the buffer
      audioBufferCache.set(url, audioBuffer);
      resolve(audioBuffer);
    } catch (error) {
      console.error('Error decoding audio data:', error);
      reject(error);
    } finally {
      activeDecodingCount--;
    }
  }
  
  isProcessingQueue = false;
  
  // If there are still items in the queue and we have capacity, continue processing
  if (decodingQueue.length > 0 && activeDecodingCount < MAX_CONCURRENT_DECODING) {
    setTimeout(processDecodingQueue, 0);
  }
};

/**
 * Load and cache audio buffer from URL
 * @param {string} url - Audio file URL
 * @returns {Promise<AudioBuffer>} - Decoded audio buffer
 */
export const loadAudioBuffer = async (url) => {
  // Check cache first
  const cached = audioBufferCache.get(url);
  if (cached) {
    return cached;
  }

  try {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    
    // Add to decoding queue
    return new Promise((resolve, reject) => {
      decodingQueue.push({ url, arrayBuffer, resolve, reject });
      processDecodingQueue(); // Start processing queue
    });
  } catch (error) {
    console.error('Error loading audio buffer:', error);
    throw error;
  }
};

/**
 * Get signed URL and load audio buffer
 * @param {string} filePath - File path to get signed URL for
 * @returns {Promise<{audioBuffer: AudioBuffer, signedUrl: string}>} - Decoded audio buffer and signed URL
 */
export const getAudioBufferFromFilePath = async (filePath) => {
  try {
    // First get signed URL
    const signedUrl = await getSignedUrl(filePath);
    if (!signedUrl) {
      throw new Error('Failed to get signed URL');
    }
    
    // Then load buffer
    const audioBuffer = await loadAudioBuffer(signedUrl);
    
    return { audioBuffer, signedUrl };
  } catch (error) {
    console.error('Error loading audio from file path:', error);
    throw error;
  }
};

/**
 * Check if audio file is cached
 * @param {string} url - Audio file URL
 * @returns {boolean} - Whether the file is cached
 */
export const isAudioBufferCached = (url) => {
  return audioBufferCache.has(url);
};

/**
 * Clear specific audio buffer from cache
 * @param {string} url - Audio file URL
 */
export const clearAudioBufferCache = (url) => {
  if (url) {
    audioBufferCache.delete(url);
  } else {
    audioBufferCache.clear();
  }
};

/**
 * Get the current size of the audio buffer cache
 * @returns {number} - Number of items in cache
 */
export const getAudioBufferCacheSize = () => {
  return audioBufferCache.getSize();
};

/**
 * Get the current queue status
 * @returns {Object} - Queue status information
 */
export const getAudioDecodingStatus = () => {
  return {
    queueLength: decodingQueue.length,
    activeDecodingCount,
    cacheSize: audioBufferCache.getSize()
  };
}; 