// src/pages/CategoryPage.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import SongCard from '../components/SongCard';
import SongListItem from '../components/SongListItem';
import { getAllSongs } from '../utils/songUtils';
import TopText from '../components/TopText'; 
import { tagIcons, tagColors } from '../constants/tagConfig';
import { FaThLarge, FaList, FaFilter, FaTimes } from 'react-icons/fa';

// Styled Components
const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3; /* Pale Stone Gray */
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 480px) {
    padding: 15px 10px;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 40px);
  max-width: 1200px;
  margin: 20px 0;
  padding: 0;
  box-sizing: border-box;
  gap: 16px;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
    margin: 15px 0;
  }
`;

const ViewToggle = styled.div`
  display: flex;
  gap: 12px;
  background: #fff;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 50px; /* Setting explicit height */
  box-sizing: border-box;
  align-items: center;

  @media (max-width: 480px) {
    gap: 6px;
    padding: 6px;
    height: 44px;
    width: 100%;
  }
`;

const ViewToggleButton = styled.button`
  background: ${props => props.$active ? '#2e2e2e' : 'none'};
  color: ${props => props.$active ? '#fff' : '#2e2e2e'};
  border: 1px solid #2e2e2e;
  border-radius: 6px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
  font-size: 0.9em;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.$active ? '#2e2e2e' : 'rgba(46, 46, 46, 0.1)'};
  }

  svg {
    font-size: 1.1em;
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 0.8em;
    gap: 6px;
    flex: 1;
    
    svg {
      font-size: 1em;
    }
  }
`;

const FilterToggle = styled.div`
  display: flex;
  background: ${props => props.$active ? '#2e2e2e' : '#fff'};
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 50px; /* Match ViewToggle height */
  box-sizing: border-box;
  align-items: center;
  transition: all 0.2s;

  @media (max-width: 480px) {
    padding: 6px;
    height: 44px;
    width: 100%;
  }
`;

const FilterButton = styled(ViewToggleButton)`
  // Inherits all styles from ViewToggleButton
  border: none; /* Remove the black outline */
  background: transparent; /* Make it transparent to let container color show */
  color: ${props => props.$active ? '#fff' : '#2e2e2e'};
  
  &:hover {
    background: transparent; /* Keep transparent on hover */
  }
`;

const FilterPanel = styled.div`
  position: relative;
  width: calc(100% - 40px);
  max-width: 1200px;
  overflow: hidden;
  max-height: ${props => props.$isOpen ? '500px' : '0'};
  opacity: ${props => props.$isOpen ? '1' : '0'};
  margin-bottom: ${props => props.$isOpen ? '20px' : '0'};
  transition: max-height 0.3s ease, opacity 0.3s ease, margin-bottom 0.3s ease;
  border-radius: 8px;
  box-shadow: none;
  box-sizing: border-box;
  background: transparent;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 480px) {
    width: calc(100% - 20px);
  }
`;

const FilterContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 480px) {
    padding: 15px 10px;
    gap: 10px;
  }
`;

const FilterTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 10px;
  justify-content: center;

  @media (max-width: 480px) {
    gap: 8px;
    margin-top: 6px;
  }
`;

const FilterTag = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 20px;
  font-family: 'Rubik', sans-serif;
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.2s;
  background: ${props => props.$selected ? props.color || '#2e2e2e' : '#E0E0E0'};
  color: ${props => props.$selected ? '#fff' : '#666'};
  border: 1px solid ${props => props.$selected ? props.color || '#2e2e2e' : '#BBBBBB'};

  &:hover {
    background: ${props => props.$selected ? props.color || '#2e2e2e' : '#d0d0d0'};
    color: ${props => props.$selected ? '#fff' : '#444'};
    border: 1px solid ${props => props.$selected ? props.color || '#2e2e2e' : '#BBBBBB'};
  }

  svg {
    font-size: 1.1em;
  }

  @media (max-width: 480px) {
    padding: 6px 10px;
    font-size: 0.8em;
    gap: 4px;
    
    svg {
      font-size: 1em;
    }
  }
`;

const SongGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  width: calc(100% - 40px); /* Account for padding */
  max-width: 1200px;
  margin: 20px auto 0;
  justify-items: center;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 30px;
    width: calc(100% - 20px); /* Smaller padding on mobile */
  }
`;

const SongList = styled.div`
  width: calc(100% - 40px);
  max-width: 1200px;
  margin: 20px auto 0;
  padding: 0;
  box-sizing: border-box;

  @media (max-width: 600px) {
    width: calc(100% - 20px);
  }
`;

const EmptyMessage = styled.p`
  font-family: 'Rubik', sans-serif;
  color: #6A7D8B; /* Muted Slate Blue */
  font-size: 18px;
  margin-top: 30px;
  text-align: center;
`;

const Sentinel = styled.div`
  height: 1px;
`;

const normalizeUrl = (url) => {
  if (!url) return url;
  return url.replace(/^www\./, '');
};

function CategoryPage() {
  const { category } = useParams();
  const [allSongs, setAllSongs] = useState([]);
  const [visibleSongs, setVisibleSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'
  const [playingSongId, setPlayingSongId] = useState(null);
  const [songProgress, setSongProgress] = useState({});
  const observer = useRef();
  
  // Filter state
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);

  const songsPerLoad = 8;

  const capitalizedCategory = category.charAt(0).toUpperCase() + category.slice(1);
  const IconComponent = tagIcons[capitalizedCategory];

  useEffect(() => {
    const fetchSongs = async () => {
      try {
        setLoading(true);
        setError(null);
        const all = await getAllSongs();
        
        const filteredSongs = all.filter((song) => {
          // Ensure song URLs are normalized when comparing
          if (song.song_file_wm) {
            song.song_file_wm = normalizeUrl(song.song_file_wm);
          }
          if (song.song_art) {
            song.song_art = normalizeUrl(song.song_art);
          }
          return song.song_tags.includes(capitalizedCategory);
        });
        
        // Sort songs: featured first, then by date
        const sortedSongs = filteredSongs.sort((a, b) => {
          // If one is featured and other isn't, featured comes first
          if (a.song_featured && !b.song_featured) return -1;
          if (!a.song_featured && b.song_featured) return 1;
          // If both are featured or both aren't, sort by date
          return new Date(b.date_added) - new Date(a.date_added);
        });
        
        // Extract all unique tags from songs for filter
        const tags = new Set();
        sortedSongs.forEach(song => {
          song.song_tags.forEach(tag => {
            if (tag !== capitalizedCategory) { // Don't include current category
              tags.add(tag);
            }
          });
        });
        
        setAvailableTags(Array.from(tags));
        setAllSongs(sortedSongs);
        setFilteredSongs(sortedSongs);
        setVisibleSongs(sortedSongs.slice(0, songsPerLoad));
      } catch (err) {
        console.error('Error fetching songs:', err);
        setError('Failed to fetch songs. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSongs();
  }, [category, capitalizedCategory]);

  const loadMoreSongs = useCallback(() => {
    setVisibleSongs((prevSongs) => {
      const nextSongs = filteredSongs.slice(
        prevSongs.length,
        prevSongs.length + songsPerLoad
      );
      return [...prevSongs, ...nextSongs];
    });
  }, [filteredSongs]);

  const sentinelRef = useRef();

  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        visibleSongs.length < filteredSongs.length
      ) {
        loadMoreSongs();
      }
    });

    if (sentinelRef.current) {
      observer.current.observe(sentinelRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loading, visibleSongs, filteredSongs, loadMoreSongs]);

  const handlePlayToggle = (songId, progress) => {
    if (typeof progress === 'number') {
      // Handle seeking
      setSongProgress(prev => ({
        ...prev,
        [songId]: progress
      }));
    } else {
      // Handle play/pause
      setPlayingSongId(prevId => prevId === songId ? null : songId);
    }
  };
  
  const toggleFilter = () => {
    if (selectedTags.length > 0) {
      // If there are active filters, clear them
      setSelectedTags([]);
      setFilteredSongs(allSongs);
      setVisibleSongs(allSongs.slice(0, songsPerLoad));
      setIsFilterOpen(false);
    } else {
      // Otherwise toggle filter panel
      setIsFilterOpen(!isFilterOpen);
    }
  };
  
  const toggleTagSelection = (tag) => {
    setSelectedTags(prev => {
      const newSelectedTags = prev.includes(tag)
        ? prev.filter(t => t !== tag)
        : [...prev, tag];
      
      // Apply filtering immediately
      applyFiltersWithTags(newSelectedTags);
      
      return newSelectedTags;
    });
  };
  
  const applyFiltersWithTags = (tags) => {
    if (tags.length === 0) {
      // No filters selected, show all songs
      setFilteredSongs(allSongs);
    } else {
      // Filter songs that have ALL selected tags (AND condition)
      const filtered = allSongs.filter(song => 
        tags.every(tag => song.song_tags.includes(tag))
      );
      setFilteredSongs(filtered);
    }
    
    // Reset visible songs to show first batch
    setVisibleSongs([]);
    setTimeout(() => {
      setVisibleSongs(prev => {
        const filtered = tags.length === 0 
          ? allSongs 
          : allSongs.filter(song => tags.every(tag => song.song_tags.includes(tag)));
        return filtered.slice(0, songsPerLoad);
      });
    }, 0);
  };

  return (
    <PageContainer>
      <Helmet>
        <title>{capitalizedCategory} | Tempo Stock</title>
        <meta name="description" content={`Browse our collection of ${capitalizedCategory.toLowerCase()} tracks on Tempo Stock.`} />
        <meta property="og:title" content={`${capitalizedCategory} | Tempo Stock`} />
        <meta property="og:description" content={`Browse our collection of ${capitalizedCategory.toLowerCase()} tracks on Tempo Stock.`} />
        <meta name="twitter:title" content={`${capitalizedCategory} | Tempo Stock`} />
        <meta name="twitter:description" content={`Browse our collection of ${capitalizedCategory.toLowerCase()} tracks on Tempo Stock.`} />
      </Helmet>

      {IconComponent && <TopText icon={IconComponent} title={capitalizedCategory} />}

      <ControlsContainer>
        <ViewToggle>
          <ViewToggleButton
            $active={viewMode === 'grid'}
            onClick={() => setViewMode('grid')}
          >
            <FaThLarge />
            Grid
          </ViewToggleButton>
          <ViewToggleButton
            $active={viewMode === 'list'}
            onClick={() => setViewMode('list')}
          >
            <FaList />
            List
          </ViewToggleButton>
        </ViewToggle>
        
        <FilterToggle $active={isFilterOpen}>
          <FilterButton 
            onClick={toggleFilter}
            $active={isFilterOpen}
          >
            {selectedTags.length > 0 ? <FaTimes /> : isFilterOpen ? <FaTimes /> : <FaFilter />}
            {selectedTags.length > 0 ? 'Clear Filter' : 'Filter'}
          </FilterButton>
        </FilterToggle>
      </ControlsContainer>
      
      <FilterPanel $isOpen={isFilterOpen}>
        <FilterContent>
          <FilterTagsContainer>
            {availableTags.map(tag => {
              const TagIcon = tagIcons[tag];
              return (
                <FilterTag 
                  key={tag}
                  $selected={selectedTags.includes(tag)}
                  onClick={() => toggleTagSelection(tag)}
                  color={tagColors[tag]}
                >
                  {TagIcon && <TagIcon />}
                  {tag}
                </FilterTag>
              );
            })}
          </FilterTagsContainer>
        </FilterContent>
      </FilterPanel>

      {loading && <EmptyMessage>Loading songs...</EmptyMessage>}
      {error && <EmptyMessage>{error}</EmptyMessage>}

      {!loading && !error && (
        <>
          {filteredSongs.length > 0 ? (
            <>
              {viewMode === 'grid' ? (
                <SongGrid>
                  {visibleSongs.map((song) => (
                    <SongCard 
                      key={song.song_id} 
                      song={song} 
                      shouldTrackPlay={true}
                      isFeatured={song.song_featured}
                    />
                  ))}
                </SongGrid>
              ) : (
                <SongList>
                  {visibleSongs.map((song) => (
                    <SongListItem
                      key={song.song_id}
                      song={song}
                      isPlaying={playingSongId === song.song_id}
                      onPlayToggle={(progress) => handlePlayToggle(song.song_id, progress)}
                      progress={songProgress[song.song_id] || 0}
                    />
                  ))}
                </SongList>
              )}
              {visibleSongs.length < filteredSongs.length && (
                <>
                  <Sentinel ref={sentinelRef} />
                  <EmptyMessage>Loading more songs...</EmptyMessage>
                </>
              )}
            </>
          ) : (
            <EmptyMessage>No songs found with the selected filters.</EmptyMessage>
          )}
        </>
      )}
    </PageContainer>
  );
}

export default CategoryPage;