import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { FaInfoCircle } from 'react-icons/fa';
import TopText from '../components/TopText';

const PageContainer = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E3E3;
  min-height: calc(100vh - 80px);
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  margin: 30px 0 20px;
  font-size: 24px;
`;

const Description = styled.p`
  font-family: 'Rubik', sans-serif;
  color: #2E2E2E;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const BoldText = styled.strong`
  color: #2E2E2E;
  font-weight: 600;
`;

const List = styled.ul`
  font-family: 'Rubik', sans-serif;
  color: #2E2E2E;
  font-size: 18px;
  line-height: 1.6;
  margin: 20px 0;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  &::marker {
    color: #6A7D8B;
  }
`;

const EngravedSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  padding-bottom: 40px;
`;

const EngravedLogo = styled.img`
  width: 60px;
  height: 60px;
  opacity: 0.4;
`;

const EngravedText = styled.p`
  font-family: 'Rubik', sans-serif;
  color: rgba(46, 46, 46, 0.4);
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
  letter-spacing: 0.5px;
`;

const DecorativeLine = styled.div`
  height: 1px;
  width: 80%;
  max-width: 500px;
  background-color: rgba(46, 46, 46, 0.2);
  margin: ${props => props.position === 'top' ? '0 auto 25px' : '25px auto 0'};
`;

const TaglineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0;
  width: 100%;
`;

const Tagline = styled.h1`
  font-family: 'DM Serif Text', serif;
  color: #2E2E2E;
  font-size: 36px;
  text-align: center;
  margin: 20px 0;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.3;
  padding: 0 20px;
  
  span {
    display: inline-block;
    position: relative;
    
    &:nth-child(odd) {
      color: #2E2E2E;
    }
    
    &:nth-child(6) {
      color: #C6A15B;
      font-weight: 700;
    }
  }
`;

function AboutPage() {
  return (
    <PageContainer>
      <Helmet>
        <title>About Us | Tempo Stock</title>
        <meta name="description" content="Discover Tempo Stock - a groundbreaking AI-powered stock music library where innovation meets artistry. 90% of revenue goes back to the music community." />
        <meta property="og:title" content="About Us | Tempo Stock" />
        <meta property="og:description" content="Discover Tempo Stock - a groundbreaking AI-powered stock music library where innovation meets artistry. 90% of revenue goes back to the music community." />
        <meta name="twitter:title" content="About Us | Tempo Stock" />
        <meta name="twitter:description" content="Discover Tempo Stock - a groundbreaking AI-powered stock music library where innovation meets artistry. 90% of revenue goes back to the music community." />
      </Helmet>
      <ContentWrapper>
        <TopText icon={FaInfoCircle} title="About Us" />
        
        <TaglineContainer>
          <DecorativeLine position="top" />
          <Tagline>
            <span>AI</span> <span>stock</span> <span>music</span> <span>that</span> <span>pays</span> <span>artists</span><span>, not</span> <span>robots</span>
          </Tagline>
          <DecorativeLine position="bottom" />
        </TaglineContainer>
        
        <Description>
          Welcome to <BoldText>Tempo Stock</BoldText>, a stock music library built and owned by composers. Our mission is simple: offer high-quality, AI-generated music that benefits the people who actually create it.
        </Description>

        <Section>
          <SectionTitle>Community First</SectionTitle>
          <Description>
          We believe music should uplift both the listener and the creator. That's why <BoldText>90% of our revenue goes back to songwriters</BoldText> in the community. By choosing Tempo Stock, you're supporting a system where artists come first, not just another tech platform.
          </Description>
        </Section>

        <Section>
          <SectionTitle>AI Meets Human Touch</SectionTitle>
          <Description>
          Every track in our library starts with cutting-edge AI and is then curated by experienced composers. This means you get fresh, original music that still feels handcrafted. It's tech-driven, but grounded in real musical sensibilities.          </Description>
        </Section>

        <Section>
          <SectionTitle>Why Tempo Stock?</SectionTitle>
          <List>
            <ListItem><BoldText>Quality & Authenticity</BoldText>: Each track is vetted by professionals who know what real music-making involves.</ListItem>
            <ListItem><BoldText>Fair Compensation</BoldText>: The majority of our revenue goes right back to songwriters.</ListItem>
            <ListItem><BoldText>Artist-Centered Innovation</BoldText>: We use technology to open doors for creators, not to replace them.</ListItem>
          </List>
        </Section>

        <Description>
        Join us in shaping a future where AI and artistry work together. Explore our catalog, find your next favorite track, and help power the next generation of music — one download at a time.        </Description>

        <EngravedSection>
          <EngravedLogo src="/images/logo/tempo_hat.svg" alt="Tempo Stock Hat Logo" />
          <EngravedText>Homegrown in Nashville</EngravedText>
        </EngravedSection>
      </ContentWrapper>
    </PageContainer>
  );
}

export default AboutPage;