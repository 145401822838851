import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { getRandomSongs } from './utils/songUtils';
import { getAudioBufferFromFilePath } from './utils/audioContext';

const SongContext = createContext();

export const useSongContext = () => useContext(SongContext);

export const SongProvider = ({ children }) => {
    const [currentSongId, setCurrentSongId] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [audioBuffers, setAudioBuffers] = useState({});
    const audioRef = useRef(new Audio());
    const currentAudioUrlRef = useRef(null);

    // Update progress based on audio element's timeupdate
    useEffect(() => {
        const audio = audioRef.current;
        
        const updateProgress = () => {
            if (audio && audio.duration) {
                const progress = (audio.currentTime / audio.duration) * 100;
                setCurrentProgress(progress);
            }
        };

        // Listen for timeupdate events to track progress
        audio.addEventListener('timeupdate', updateProgress);
        
        // Handle end of playback
        const handleEnded = () => {
            setIsPlaying(false);
            setCurrentProgress(0);
        };
        audio.addEventListener('ended', handleEnded);

        return () => {
            audio.removeEventListener('timeupdate', updateProgress);
            audio.removeEventListener('ended', handleEnded);
        };
    }, []);

    /**
     * Load audio buffer for a specific song
     * @param {string} songId - Song ID
     * @param {string} filePath - Path to the audio file
     * @returns {Promise<{buffer: AudioBuffer, url: string}>}
     */
    const loadAudioBuffer = async (songId, filePath) => {
        try {
            // Check if we already have this buffer
            if (audioBuffers[songId]) {
                return audioBuffers[songId];
            }
            
            // Load buffer through shared context
            const { audioBuffer, signedUrl } = await getAudioBufferFromFilePath(filePath);
            
            // Store the buffer and URL
            const newBuffers = { 
                ...audioBuffers, 
                [songId]: { buffer: audioBuffer, url: signedUrl } 
            };
            setAudioBuffers(newBuffers);
            
            return { buffer: audioBuffer, url: signedUrl };
        } catch (error) {
            console.error('Error loading audio buffer:', error);
            throw error;
        }
    };

    /**
     * Play a specific song
     * @param {string} songId - Song ID
     * @param {string} filePath - Path to the audio file
     * @param {number} [startPosition=0] - Starting position in percentage (0-100)
     * @returns {Promise<boolean>}
     */
    const playSong = async (songId, filePath, startPosition = 0) => {
        try {
            // Stop current playback
            audioRef.current.pause();
            
            // Set as current song
            setCurrentSongId(songId);
            
            // Load buffer if needed
            let url;
            try {
                const { url: signedUrl } = await loadAudioBuffer(songId, filePath);
                url = signedUrl;
            } catch (error) {
                console.error('Error loading buffer for playback:', error);
                return false;
            }
            
            // Set source if changed
            if (currentAudioUrlRef.current !== url) {
                audioRef.current.src = url;
                currentAudioUrlRef.current = url;
                await new Promise(resolve => {
                    audioRef.current.addEventListener('canplay', resolve, { once: true });
                    audioRef.current.load();
                });
            }
            
            // Set position if specified
            if (startPosition > 0 && startPosition <= 100) {
                audioRef.current.currentTime = (startPosition / 100) * audioRef.current.duration;
            }
            
            // Start playback
            await audioRef.current.play();
            setIsPlaying(true);
            return true;
        } catch (error) {
            console.error('Error playing song:', error);
            setIsPlaying(false);
            return false;
        }
    };

    /**
     * Pause current playback
     */
    const pausePlayback = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    /**
     * Resume current playback
     * @returns {Promise<boolean>}
     */
    const resumePlayback = async () => {
        if (audioRef.current && currentSongId) {
            try {
                await audioRef.current.play();
                setIsPlaying(true);
                return true;
            } catch (error) {
                console.error('Error resuming playback:', error);
                setIsPlaying(false);
                return false;
            }
        }
        return false;
    };

    /**
     * Seek to position in current song
     * @param {number} position - Position in percentage (0-100)
     */
    const seekTo = (position) => {
        if (audioRef.current && audioRef.current.duration) {
            const seekTime = (position / 100) * audioRef.current.duration;
            audioRef.current.currentTime = seekTime;
            setCurrentProgress(position);
        }
    };

    const fetchRandomSongs = async (count = 1) => {
        return await getRandomSongs(count);
    };

    return (
        <SongContext.Provider value={{ 
            currentSongId, 
            setCurrentSongId,
            isPlaying,
            currentProgress,
            audioRef,
            loadAudioBuffer,
            playSong,
            pausePlayback,
            resumePlayback,
            seekTo,
            fetchRandomSongs,
            audioBuffers
        }}>
            {children}
        </SongContext.Provider>
    );
};