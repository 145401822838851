// src/pages/SongPage.js

import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { getSongById } from '../utils/songUtils';
import { getSignedUrl } from '../utils/getSignedUrl';
import { AuthContext } from '../context/AuthContext';
import { FaStar, FaExclamationCircle, FaFire, FaCrown } from 'react-icons/fa';
import { HiCheck } from 'react-icons/hi';
import { tagColors, tagIcons } from '../constants/tagConfig';
import CustomAudioPlayer from '../components/CustomAudioPlayer';

// Styled Components
const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e3e3e3; /* Pale Stone Gray */

  /* Adjust padding for smaller screens */
  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const SongTitle = styled.h1`
  font-family: 'DM Serif Text', serif;
  color: #2e2e2e;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: ${props => props.$hasPills ? '20px' : '30px'};
`;

const PillContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0;
  margin-top: 30px;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`;

const FeaturedPill = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border: 1px solid #2e2e2e;
  border-radius: 8px;
  color: #2e2e2e;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;

  svg {
    margin-right: 5px;
    color: #2e2e2e;
  }
`;

const TrendingPill = styled(FeaturedPill)`
  color: #2e2e2e;
  
  svg {
    margin-right: 3px;
    color: #2e2e2e;
  }
`;

const FireIconGroup = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-top: 0;
  margin-bottom: 10px;
`;

const TagPill = styled.a`
  && { /* Increased specificity using double ampersand */
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid ${(props) => props.color || '#000'};
    border-radius: 20px;
    color: ${(props) => props.color || '#000'};
    font-family: 'Rubik', sans-serif;
    font-size: 0.9em;
    font-weight: 400;
    text-decoration: none; /* Remove underline */
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.color || '#000'};
      color: #fff; /* Change text color to white */
    }

    svg {
      margin-right: 5px;
    }
  }
`;

const BpmKeyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 2rem;
`;

const BpmKeyPill = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #696969; /* Dim Gray Outline */
  border-radius: 0px; /* Changed from 20px to 0px for square corners */
  color: #696969; /* Dim Gray Text */
  font-family: 'Rubik', sans-serif;
  font-size: 0.9em;
`;

const SongTags = styled.div`
  margin: 0 0 20px 0;
  text-align: center;
`;

const AlbumArtContainer = styled.div`
  width: 400px;
  height: 400px;
  margin: 20px 0px;
  position: relative;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  overflow: hidden;
  background: #2E2E2E;

  /* Responsive adjustments */
  @media (max-width: 1024px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
  }

  @media (max-width: 480px) {
    width: 90vw;
    height: 90vw;
  }
`;

const BlurImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: ${props => props.$blurDataUrl ? `url(${props.$blurDataUrl})` : 'none'};
  background-size: cover;
  background-position: center;
  filter: blur(10px);
  transform: scale(1.1);
  transition: opacity 0.3s ease-out;
  opacity: ${props => props.$isLoading ? 1 : 0};
`;

const MainAlbumArt = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: ${props => props.$image ? `url(${props.$image})` : 'none'};
  background-size: cover;
  background-position: center;
  opacity: ${props => props.$isLoaded ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
`;

// DownloadButton with dynamic styles based on disabled state
const DownloadButton = styled.button`
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  text-decoration: none;
  color: ${(props) => (props.disabled ? '#a0a0a0' : '#fff')};
  background-color: ${(props) => (props.disabled ? '#cccccc' : '#2e2e2e')};
  border: none;
  border-radius: 8px;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  max-width: 250px;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#cccccc' : '#444')};
  }
`;

// Updated DownloadDisclaimer message
const DownloadDisclaimer = styled.div`
  margin-top: 10px;
  text-align: center;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;

  p {
    font-family: 'Rubik', sans-serif;
    color: #a0a0a0;
    font-size: 12px;
    margin: 5px 0;
    white-space: pre-line;
  }

  .downloads-remaining {
    margin: 15px 0 4px;
    display: block;
  }

  .upgrade-text {
    display: block;
    margin-bottom: 15px;
  }

  a {
    color: #2e2e2e;
    text-decoration: underline;
    &:hover {
      color: #444;
    }
  }
`;

const DownloadStatusBar = styled.div`
  margin-top: 10px;
  padding: 8px 30px;
  background-color: ${props => props.$canDownload ? '#f0fdf4' : '#fef2f2'};
  color: ${props => props.$canDownload ? '#166534' : '#991b1b'};
  border: 1px solid ${props => props.$canDownload ? '#bbf7d0' : '#fecaca'};
  border-radius: 8px;
  font-family: 'Rubik', sans-serif;
  font-size: 0.9em;
  text-align: center;
  width: 100%;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  
  svg {
    color: ${props => props.$canDownload ? '#16a34a' : '#dc2626'};
    flex-shrink: 0;
  }
`;

const PremiumDownloadBadge = styled.div`
  margin-top: 10px;
  padding: 8px 30px;
  background-color: #f0f9ff;
  color: #0c4a6e;
  border: 1px solid #bae6fd;
  border-radius: 8px;
  font-family: 'Rubik', sans-serif;
  font-size: 0.9em;
  text-align: center;
  width: 100%;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  
  svg {
    color: #0284c7;
    flex-shrink: 0;
  }
`;

const SongPage = () => {
  const { songId } = useParams();
  const { user, getDownloadStatus } = useContext(AuthContext);
  const [song, setSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [albumArtSrc, setAlbumArtSrc] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [metaImageUrl, setMetaImageUrl] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [blurDataUrl, setBlurDataUrl] = useState('');
  const [trendingRank, setTrendingRank] = useState(0);
  const [downloadStatus, setDownloadStatus] = useState({
    canDownload: false,
    remainingDownloads: 0,
    weeklyLimit: 0,
    message: '',
    unlimited: false
  });

  // Function to get canonical URL without www
  const getCanonicalUrl = () => {
    const url = new URL(window.location.href);
    // Remove www if present
    url.hostname = url.hostname.replace(/^www\./, '');
    return url.toString();
  };

  // Generate a tiny blur placeholder
  const generateBlurPlaceholder = (width = 20) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = width; // Square image

    ctx.fillStyle = '#2E2E2E';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    return canvas.toDataURL('image/jpeg', 0.1);
  };

  useEffect(() => {
    const fetchSong = async () => {
      try {
        const songData = await getSongById(songId);
        setSong(songData);
        console.log('Fetched song:', songData);

        // Get trending rank
        try {
          const trendingResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/songs/trending`);
          if (trendingResponse.ok) {
            const trendingSongs = await trendingResponse.json();
            console.log('Trending songs:', trendingSongs);
            console.log('Current song ID:', songId);
            
            // Ensure both IDs are strings for comparison
            const rank = trendingSongs.findIndex(s => String(s.song_id) === String(songId)) + 1;
            console.log('Calculated rank:', rank);
            setTrendingRank(rank > 0 ? rank : 0);
          } else {
            console.error('Failed to fetch trending songs:', await trendingResponse.text());
            setTrendingRank(0);
          }
        } catch (trendingError) {
          console.error('Error fetching trending data:', trendingError);
          setTrendingRank(0);
        }

        // Fetch signed URL for audio file
        if (songData.song_file_wm) {
          const audioPath = songData.song_file_wm.startsWith('/') 
            ? songData.song_file_wm.slice(1) 
            : songData.song_file_wm;
          
          const signedAudioUrl = await getSignedUrl(audioPath);
          if (signedAudioUrl) {
            setAudioUrl(signedAudioUrl);
          }
        }

        // Handle album art loading
        if (songData.song_art) {
          // Set blur placeholder immediately
          setBlurDataUrl(generateBlurPlaceholder());
          
          const artPath = songData.song_art.startsWith('/') 
            ? songData.song_art.slice(1) 
            : songData.song_art;
          
          // Get two signed URLs - one for display and one for meta tags
          const displaySignedArtUrl = await getSignedUrl(artPath); // Default 5 min expiration for display
          const metaSignedArtUrl = await getSignedUrl(artPath, 3600); // 1 hour expiration for meta tags
          
          if (displaySignedArtUrl) {
            // Preload the image for display
            const img = new Image();
            img.onload = () => {
              setAlbumArtSrc(displaySignedArtUrl);
              setImageLoaded(true);
            };
            img.src = displaySignedArtUrl;
            
            // Set meta image URL separately with longer expiration
            setMetaImageUrl(metaSignedArtUrl);
          } else {
            setAlbumArtSrc('/images/categories/ambient.jpg');
            setMetaImageUrl('https://tempostock.com/images/social.png');
          }
        } else {
          setAlbumArtSrc('/images/categories/ambient.jpg');
          setMetaImageUrl('https://tempostock.com/images/social.png');
        }
      } catch (error) {
        console.error('Error fetching song:', error);
        setAlbumArtSrc('/images/categories/ambient.jpg');
        setMetaImageUrl('https://tempostock.com/images/social.png');
      }
    };

    fetchSong();
  }, [songId]);

  useEffect(() => {
    const fetchDownloadStatus = async () => {
      if (!user) return;

      const status = await getDownloadStatus();
      console.log('Download status:', status);
      console.log('Is Premier user?', user.account_type === 'Premier');
      console.log('Is unlimited?', status.unlimited);
      console.log('User account type:', user.account_type);
      console.log('Worker version:', status.workerVersion || 'not specified');
      setDownloadStatus(status);
    };

    fetchDownloadStatus();
  }, [user, songId, getDownloadStatus]);

  // Add effect to monitor trending rank changes
  useEffect(() => {
    console.log('Trending rank updated:', trendingRank);
  }, [trendingRank]);

  const handleAlbumArtClick = (e) => {
    // Don't trigger if clicking on the player controls
    if (e.target.closest('.player-controls')) {
      return;
    }
    setIsPlaying(!isPlaying);
  };

  const handleDownload = async () => {
    if (!user) {
      alert('Please log in to download tracks.');
      return;
    }

    setDownloadLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/download/${song.song_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const downloadUrl = data.downloadUrl;

        const res = await fetch(downloadUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (res.ok) {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;

          const contentDisposition = res.headers.get('Content-Disposition');
          let filename = `${song.song_title}.mp3`;

          if (contentDisposition && contentDisposition.includes('filename=')) {
            filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
          }

          link.download = filename;
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);

          // Refresh download status after successful download
          const newStatus = await getDownloadStatus();
          setDownloadStatus(newStatus);
        } else {
          const errorData = await res.json();
          console.error('Download failed:', errorData.error);
          alert(`Download failed: ${errorData.error}`);
        }
      } else if (response.status === 403) {
        const data = await response.json();
        alert(data.error || 'Download limit reached.');
        const newStatus = await getDownloadStatus();
        setDownloadStatus(newStatus);
      } else {
        const errorData = await response.json();
        console.error('Download failed:', errorData.error);
        alert(`Download failed: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error during download:', error);
      alert('An unexpected error occurred during the download.');
    } finally {
      setDownloadLoading(false);
    }
  };

  // Function to get trending text
  const getTrendingText = () => {
    console.log('Getting trending text for rank:', trendingRank);
    if (trendingRank === 1) {
      return (
        <>
          <FireIconGroup>
            <FaFire />
          </FireIconGroup>
          Trending #1
        </>
      );
    }
    if (trendingRank > 1 && trendingRank <= 10) {
      return (
        <>
          <FireIconGroup>
            <FaFire />
          </FireIconGroup>
          Top 10 Trending
        </>
      );
    }
    if (trendingRank > 10 && trendingRank <= 50) {
      return (
        <>
          <FireIconGroup>
            <FaFire />
          </FireIconGroup>
          Top 50 Trending
        </>
      );
    }
    return '';
  };

  if (!song) return <p>Loading...</p>;

  const canonicalUrl = getCanonicalUrl();

  return (
    <PageContainer>
      <Helmet>
        <title>{song.song_title} | Tempo Stock</title>
        <meta name="description" content={`Listen to ${song.song_title} by ${song.song_artist} on Tempo Stock - Royalty-free music for your projects.`} />
        
        {/* Open Graph Facebook */}
        <meta property="og:type" content="music.song" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={`${song.song_title} | Tempo Stock`} />
        <meta property="og:description" content={`Listen to ${song.song_title} by ${song.song_artist} on Tempo Stock - Royalty-free music for your projects.`} />
        <meta property="og:image" content={metaImageUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta name="twitter:title" content={`${song.song_title} | Tempo Stock`} />
        <meta name="twitter:description" content={`Listen to ${song.song_title} by ${song.song_artist} on Tempo Stock - Royalty-free music for your projects.`} />
        <meta name="twitter:image" content={metaImageUrl} />
        
        {/* Additional Meta Tags */}
        <meta property="og:site_name" content="Tempo Stock" />
        <meta property="og:audio" content={song.song_file_wm} />
        <meta property="og:audio:type" content="audio/mpeg" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      {(song?.song_featured || (trendingRank > 0 && trendingRank <= 50)) && (
        <PillContainer>
          {song?.song_featured && (
            <FeaturedPill>
              <FaStar />
              Featured
            </FeaturedPill>
          )}
          {trendingRank > 0 && trendingRank <= 50 && (
            <TrendingPill>
              {getTrendingText()}
            </TrendingPill>
          )}
        </PillContainer>
      )}

      <SongTitle $hasPills={song?.song_featured || (trendingRank > 0 && trendingRank <= 50)}>
        {song?.song_title}
      </SongTitle>
      <SongTags>
        <TagContainer>
          {song.song_tags.slice(0, 3).map((tag) => {
            const IconComponent = tagIcons[tag] || tagIcons.Piano;
            return (
              <TagPill
                key={tag}
                color={tagColors[tag] || '#000'}
                href={`/${tag.toLowerCase()}`}
              >
                <IconComponent />
                {tag}
              </TagPill>
            );
          })}
        </TagContainer>
      </SongTags>
      {albumArtSrc && (
        <AlbumArtContainer onClick={handleAlbumArtClick}>
          <BlurImage $blurDataUrl={blurDataUrl} $isLoading={!imageLoaded} />
          <MainAlbumArt $image={albumArtSrc} $isLoaded={imageLoaded} />
          {song && audioUrl && (
            <CustomAudioPlayer
              audioUrl={audioUrl}
              isPlaying={isPlaying}
              onPlayingChange={setIsPlaying}
              songId={song.song_id}
            />
          )}
        </AlbumArtContainer>
      )}
      <BpmKeyContainer>
        <BpmKeyPill>{song.song_bpm}</BpmKeyPill>
        <BpmKeyPill>{song.song_key}</BpmKeyPill>
      </BpmKeyContainer>

      {/* Download Button and Related Visuals */}
      {user ? (
        <>
          <DownloadButton
            onClick={handleDownload}
            disabled={!downloadStatus.canDownload || downloadLoading}
          >
            {downloadLoading ? 'Downloading...' : 'Download Track'}
          </DownloadButton>

          {/* Updated Download Status Bar */}
          {downloadStatus.unlimited ? (
            <PremiumDownloadBadge>
              <FaCrown size={20} />
              Unlimited Premier Downloads
            </PremiumDownloadBadge>
          ) : (
            <DownloadStatusBar $canDownload={downloadStatus.canDownload}>
              {downloadStatus.canDownload ? (
                <>
                  <HiCheck size={22} />
                  Available for download
                </>
              ) : (
                <>
                  <FaExclamationCircle size={20} />
                  No downloads remaining this week
                </>
              )}
            </DownloadStatusBar>
          )}

          <DownloadDisclaimer>
            <p>
              {!downloadStatus.unlimited && (
                <span className="downloads-remaining">
                  Downloads remaining this week: {downloadStatus.remainingDownloads}
                </span>
              )}
              {!downloadStatus.canDownload && (
                <span className="upgrade-text">
                  {user?.account_type === 'Free' ? (
                    <a href="/pricing">Upgrade to Pro for more downloads.</a>
                  ) : user?.account_type === 'Pro' && (
                    <a href="/pricing">Upgrade to Premier for unlimited downloads.</a>
                  )}
                </span>
              )}
              By downloading you agree to our TOS.
              {user?.account_type === 'Free' && "\nFree users must credit Tempo Stock with track usage."}
            </p>
          </DownloadDisclaimer>
        </>
      ) : (
        <>
          <DownloadButton disabled>
            Download Track
          </DownloadButton>
          <DownloadDisclaimer>
            <p>
              Please <a href="/login">log in</a> to download tracks.
            </p>
          </DownloadDisclaimer>
        </>
      )}
    </PageContainer>
  );
};

export default SongPage;